import React from 'react'
import Orders from '../components/Orders/Orders'

const OrdersPage = () => {
  return (
    <Orders />
  )
}

export { OrdersPage }
