import React from 'react'
import EditPartner from '../components/Partners/EditPartner'

const EditPartnerPage = () => {
  return (
    <EditPartner />
  )
}

export { EditPartnerPage }
