import React from 'react'
import CreatePartner from '../components/Partners/CreatePartner'

const CreatePartnerPage = () => {
  return (
    <CreatePartner />
  )
}

export { CreatePartnerPage }
