import React from 'react'
import OrdersExpress from '../components/Orders/OrdersExpress'

const OrdersExpressPage = () => {
  return (
    <OrdersExpress />
  )
}

export { OrdersExpressPage }
