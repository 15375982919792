import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import OrderController from '../controllers/OrderController'

export const types = {
  GET_ORDER: 'GET_ORDER',
  SET_ORDER: 'SET_ORDER',
  UPDATE_POINT: 'UPDATE_POINT',
  CANCEL_ORDER: 'CANCEL_ORDER',
  UPDATE_POINTS: 'UPDATE_POINTS',
  ASSIGN_PARTNER: 'ASSIGN_PARTNER',
  GET_ZONES: 'GET_ZONES',
  SET_ZONES: 'SET_ZONES'
}

export const getOrder = createAsyncThunk(
  types.GET_ORDER,
  async ({ payload }, { dispatch }) => {
    const response = await OrderController.getOrder(payload)
    dispatch(setOrder(response))
    return response
  }
)

export const updatePoints = createAsyncThunk(
  types.UPDATE_POINTS,
  async ({ payload }, { dispatch }) => {
    const response = await OrderController.updateOrder(payload)
    dispatch(getOrder({ payload: { id: payload.order.id } }))
    return response
  }
)

export const updatePoint = createAsyncThunk(
  types.UPDATE_POINT,
  async ({ payload }, { dispatch }) => {
    const response = await OrderController.updatePoint(payload)
    return response
  }
)
export const cancelOrder = createAsyncThunk(
  types.CANCEL_ORDER,
  async ({ payload }, { dispatch }) => {
    const response = await OrderController.cancelOrder(payload)
    return response
  }
)
export const assignPartner = createAsyncThunk(
  types.ASSIGN_PARTNER,
  async ({ payload }, { dispatch }) => {
    try {
      const response = await OrderController.assignPartner(payload)
      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
)
export const setOrder = createAction(types.SET_ORDER, (order) => ({
  payload: order
}))

export const getZonas = createAsyncThunk(
  types.GET_ZONES,
  async (_, { dispatch }) => {
    const response = await OrderController.getZones()
    dispatch(setZones(response))
    return response
  }
)

export const setZones = createAction(types.SET_ZONES, (zones) => ({
  payload: zones
}))
