import order from './HttpApi'
import partner from './HttpOrder'
class PartnersController {
  static async getPartners (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.get(
        `/partner?limit=10&page=${payload.page ? payload.page : 1}&${payload.data}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getPartner (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await partner.get(`/admin/partners/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async postPartner (payload) {
    try {
      const response = await order.post('/partner', payload)
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updatePartner (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await partner.put(`/admin/partners/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getKmRadius () {
    try {
      const token = localStorage.getItem('token')
      const response = await order.get('/geofence', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updateKmRadius (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.post('/geofence/edit', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default PartnersController
