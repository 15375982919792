import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import SettingsController from '../controllers/SettingsController'

export const types = {
  GET_SERVICES: 'GET_SERVICES',
  SET_SERVICES: 'SET_SERVICES',
  UPDATE_SERVICES: 'UPDATE_SERVICES'
}

export const getServices = createAsyncThunk(types.GET_SERVICES, async (_, { dispatch }) => {
  const response = await SettingsController.getServices()
  dispatch(setServices(response))
  return response
})

export const updateServices = createAsyncThunk(
  types.UPDATE_SERVICES,
  async ({ payload }, { dispatch }) => {
    const response = await SettingsController.updateServices(payload)
    dispatch(setServices(response))
    return response
  }
)
export const setServices = createAction(types.SET_SERVICES, (services) => ({
  payload: services
}))
