import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import DashboardController from '../controllers/DashboardController'

export const types = {
  GET_DASHBOARD: 'GET_DASHBOARD',
  SET_DASHBOARD: 'SET_DASHBOARD',
  SET_POINTS_FINALIZED: 'SET_POINTS_FINALIZED',
  SET_ORDERS_PICK: 'SET_ORDERS_PICK',
  SET_ORDERS_COMPLETED: 'SET_ORDERS_COMPLETED',

  GET_DASHBOARD_SAMEDAY: 'GET_DASHBOARD_SAMEDAY',
  SET_DASHBOARD_SAMEDAY: 'SET_DASHBOARD_SAMEDAY',
  SET_POINTS_FINALIZED_SAMEDAY: 'SET_POINTS_FINALIZED_SAMEDAY',
  SET_ORDERS_PICK_SAMEDAY: 'SET_ORDERS_PICK_SAMEDAY',
  SET_ORDERS_COMPLETED_SAMEDAY: 'SET_ORDERS_COMPLETED_SAMEDAY',

  GET_DASHBARD_EXPRESS: 'GET_DASHBARD_EXPRESS',
  SET_DASHBARD_EXPRESS: 'SET_DASHBARD_EXPRESS',
  SET_POINTS_FINALIZED_EXPRESS: 'SET_POINTS_FINALIZED_EXPRESS',
  SET_ORDERS_PICK_EXPRESS: 'SET_ORDERS_PICK_EXPRESS',
  SET_ORDERS_COMPLETED_EXPRESS: 'SET_ORDERS_COMPLETED_EXPRESS'

}

export const getDashboard = createAsyncThunk(
  types.GET_DASHBOARD,
  async ({ date }, { dispatch }) => {
    const token = localStorage.getItem('token')
    const response = await DashboardController.getDashboard(date, token)
    dispatch(setDashboard(response))
    dispatch(setPointsFinalized(response))
    dispatch(setOrdersCompleted(response))
    dispatch(setOrdersPick(response))
    return response
  }
)
export const getDashboardSameday = createAsyncThunk(
  types.GET_DASHBOARD_SAMEDAY,
  async ({ date }, { dispatch }) => {
    const token = localStorage.getItem('token')
    const response = await DashboardController.getDashboardSameday(date, token)
    dispatch(setDashboardSameday(response))
    dispatch(setPointsFinalizedSameday(response))
    dispatch(setOrdersCompletedSameday(response))
    dispatch(setOrdersPickSameday(response))
    return response
  }
)

export const getDashboardExpress = createAsyncThunk(
  types.GET_DASHBARD_EXPRESS,
  async ({ date }, { dispatch }) => {
    const token = localStorage.getItem('token')
    const response = await DashboardController.getDashboardExpress(date, token)
    dispatch(setDashboardExpress(response))
    dispatch(setPointsFinalizedExpress(response))
    dispatch(setOrdersCompletedExpress(response))
    dispatch(setOrdersPickExpress(response))
    return response
  }
)

export const setDashboard = createAction(types.SET_DASHBOARD, (dashboard) => ({
  payload: dashboard
}))

export const setDashboardSameday = createAction(types.SET_DASHBOARD_SAMEDAY, (dashboardSameday) => ({
  payload: dashboardSameday
}))

export const setDashboardExpress = createAction(types.SET_DASHBARD_EXPRESS, (dashboardExpress) => ({
  payload: dashboardExpress
}))

export const setPointsFinalized = createAction(
  types.SET_POINTS_FINALIZED,
  (dashboard) => ({
    payload: {
      before:
        dashboard.pointsCompleted.before > 0
          ? Math.round((dashboard.pointsCompleted.before * 100) /
            (dashboard.pointsTotal))
          : 0,
      after:
        dashboard.pointsCompleted.after > 0
          ? Math.round((dashboard.pointsCompleted.after * 100) /
            (dashboard.pointsTotal))
          : 0
    }
  })
)
export const setPointsFinalizedSameday = createAction(
  types.SET_POINTS_FINALIZED_SAMEDAY,
  (dashboardSameday) => ({
    payload: {
      before:
        dashboardSameday.pointsCompleted.before > 0
          ? Math.round((dashboardSameday.pointsCompleted.before * 100) /
            (dashboardSameday.pointsTotal))
          : 0,
      after:
        dashboardSameday.pointsCompleted.after > 0
          ? Math.round((dashboardSameday.after * 100) /
            (dashboardSameday.pointsTotal))
          : 0
    }
  })
)
export const setPointsFinalizedExpress = createAction(
  types.SET_POINTS_FINALIZED_EXPRESS,
  (dashboardExpress) => ({
    payload: {
      before:
        dashboardExpress.pointsCompleted.before > 0
          ? Math.round((dashboardExpress.pointsCompleted.before * 100) /
            (dashboardExpress.pointsTotal))
          : 0,
      after:
        dashboardExpress.pointsCompleted.after > 0
          ? Math.round((dashboardExpress.pointsCompleted.after * 100) /
            (dashboardExpress.pointsTotal))
          : 0
    }
  })
)

export const setOrdersCompleted = createAction(
  types.SET_ORDERS_COMPLETED,
  (dashboard) => ({
    payload: {
      before:
        dashboard.ordersCompletedGroup.before > 0
          ? Math.round((dashboard.ordersCompletedGroup.before * 100) / dashboard.ordersTotal)
          : 0,
      after:
        dashboard.ordersCompletedGroup.after > 0
          ? Math.round((dashboard.ordersCompletedGroup.after * 100) / dashboard.ordersTotal)
          : 0
    }
  })
)

export const setOrdersCompletedSameday = createAction(
  types.SET_ORDERS_COMPLETED_SAMEDAY,
  (dashboardSameday) => ({
    payload: {
      before:
        dashboardSameday.ordersCompletedGroup.before > 0
          ? Math.round((dashboardSameday.ordersCompletedGroup.before * 100) / dashboardSameday.ordersTotal)
          : 0,
      after:
        dashboardSameday.ordersCompletedGroup.after > 0
          ? Math.round((dashboardSameday.ordersCompletedGroup.after * 100) / dashboardSameday.ordersTotal)
          : 0
    }
  })
)

export const setOrdersCompletedExpress = createAction(
  types.SET_ORDERS_COMPLETED_EXPRESS,
  (dashboardExpress) => ({
    payload: {
      before:
        dashboardExpress.ordersCompletedGroup.before > 0
          ? Math.round((dashboardExpress.ordersCompletedGroup.before * 100) / dashboardExpress.ordersTotal)
          : 0,
      after:
        dashboardExpress.ordersCompletedGroup.after > 0
          ? Math.round((dashboardExpress.ordersCompletedGroup.after * 100) / dashboardExpress.ordersTotal)
          : 0
    }
  })
)

export const setOrdersPick = createAction(
  types.SET_ORDERS_PICK,
  (dashboard) => ({
    payload: {
      before:
        dashboard.ordersPickup.before > 0
          ? Math.round((dashboard.ordersPickup.before * 100) / dashboard.ordersTotal)
          : 0,
      after:
        dashboard.ordersPickup.after > 0
          ? Math.round((dashboard.ordersPickup.after * 100) / dashboard.ordersTotal)
          : 0
    }
  })
)

export const setOrdersPickSameday = createAction(
  types.SET_ORDERS_PICK_SAMEDAY,
  (dashboardSameday) => ({
    payload: {
      before:
        dashboardSameday.ordersPickup.before > 0
          ? Math.round((dashboardSameday.ordersPickup.before * 100) / dashboardSameday.ordersTotal)
          : 0,
      after:
        dashboardSameday.ordersPickup.after > 0
          ? Math.round((dashboardSameday.ordersPickup.after * 100) / dashboardSameday.ordersTotal)
          : 0
    }
  })
)

export const setOrdersPickExpress = createAction(
  types.SET_ORDERS_PICK_EXPRESS,
  (dashboardExpress) => ({
    payload: {
      before:
        dashboardExpress.ordersPickup.before > 0
          ? Math.round((dashboardExpress.ordersPickup.before * 100) / dashboardExpress.ordersTotal)
          : 0,
      after:
        dashboardExpress.ordersPickup.after > 0
          ? Math.round((dashboardExpress.ordersPickup.after * 100) / dashboardExpress.ordersTotal)
          : 0
    }
  })
)
