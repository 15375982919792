import React from 'react'
import './dashboard.scss'
const LegendTimeLine = () => {
  return (
    <div className='legend__container'>
      <div className='item__legend'>
        <div className='legend__type' style={{ backgroundColor: '#54A268' }}></div>
        <span>Dentro del rango horario</span>
      </div>
      <div className='item__legend'>
        <div className='legend__type' style={{ backgroundColor: '#E19829' }}></div>
        <span>Fuera del rango horario</span>
      </div>
      <div className='item__legend'>
        <div className='legend__type' style={{ backgroundColor: '#f0f0f0' }}></div>
        <span>Pendiente</span>
      </div>
    </div>
  )
}

export default LegendTimeLine
