import React, { useEffect, useRef } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'
import motoOcupado from '../../assets/img/rider_ocupado.png'
// eslint-disable-next-line react/jsx-filename-extension
const GMap = ({ order, points }) => {
  let unsubscribeMap = null
  const googleMapRef = useRef(null)
  let googleMap = null
  const database = getDatabase()

  // list of the marker object along with icon
  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      zoom: 16,
      center: { lat: -12.09718, lng: -77.03258 }
    })
  }

  const getAllMenuItems = async () => {
    const mapFire = new window.google.maps.Map(googleMapRef.current, {
      zoom: 11,
      center: { lat: -12.09718, lng: -77.03258 },
      minZoom: -1000,
      maxZoom: 30
    })
    points?.map((x, index) => {
      const gWindows = new window.google.maps.Marker({
        position: {
          lat: Number.parseFloat(x.lat),
          lng: Number.parseFloat(x.lng)
        },
        map: mapFire,
        icon: {
          url: `https://s3.sa-east-1.amazonaws.com/nirex2.0/Web+app/Pines/${
            index + 1
          }.png`
        }
      })
      return gWindows
    })

    const markers = []
    const dbRef = ref(database, 'partner_location_updates')
    unsubscribeMap = onValue(dbRef, function (snapshot) {
      const drivers = []
      snapshot.forEach(function (childSnapshot) {
        const driver = childSnapshot.val()
        if (driver.orderId === order.id) {
          drivers.push(childSnapshot.val())
        }
      })

      let icon = {}
      for (let i = 0; i < drivers.length; i++) {
        const item = drivers[i]
        icon = {
          url: motoOcupado,
          scaledSize: new window.google.maps.Size(30, 30),
          anchor: new window.google.maps.Point(15, 15)
        }
        if (markers[i]) {
          markers[i].setPosition(
            new window.google.maps.LatLng(
              Number.parseFloat(item.latittude),
              Number.parseFloat(item.longuitude)
            )
          )
          if (order.status !== 'completed' && order.status !== 'canceled') {
            markers[i].setVisible(true)
          } else {
            markers[i].setVisible(false)
          }
        } else {
          const marker = new window.google.maps.Marker({
            position: {
              lat: Number.parseFloat(item.latittude),
              lng: Number.parseFloat(item.longuitude)
            },
            map: mapFire,
            icon
          })
          markers.push(marker)
        }
      }
    })
  }
  useEffect(() => {
    googleMap = initGoogleMap()
    const bounds = new window.google.maps.LatLngBounds()
    getAllMenuItems()
    googleMap.fitBounds(bounds) // the map to contain all markers
    return () => unsubscribeMap()
  }, [])

  return <div ref={googleMapRef} style={{ width: '100%', height: '100%' }} />
}

export default GMap
