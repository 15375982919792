import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as partnersActions from '../../actions/PartnersActions'
import { radiusSelector } from '../../selectors/PartnersSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { buttonLoadingStyles } from './Settings'

const AddMoreKmRadious = ({
  snackBarData,
  setSnackBarData,
  clearSnackBar
}) => {
  const dispatch = useDispatch()
  const radius = useSelector(radiusSelector)
  const [newRadius, setNewRadius] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(partnersActions.getKmRadius())
  }, [dispatch])

  const sendNewRadius = () => {
    clearSnackBar()
    setLoading(true)
    try {
      dispatch(partnersActions.updateKmRadius({ payload: { expressRadius: newRadius } }))
      dispatch(partnersActions.getKmRadius())
      setSnackBarData({
        ...snackBarData,
        message: 'Metros modificados exitosamente',
        type: 'success',
        show: true
      })
      setLoading(false)
      setTimeout(() => clearSnackBar(), 2500)
    } catch (e) {
      setSnackBarData({
        ...snackBarData,
        message: 'Error al modificar los metros',
        type: 'error',
        show: true
      })
      setLoading(false)
      setTimeout(() => clearSnackBar(), 2500)
    }
  }
  return (
    <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '3.5rem', width: '70%' }}>
      <h3 id='form-dialog-title'>Cambia el radio de acción para el rider</h3>
      <div>
        <span style={{ fontSize: '18px' }}>
          Radio actual: <b>{radius?.expressRadius ? radius?.expressRadius : 'loading'}</b> metros
        </span>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Metros'
          type='number'
          fullWidth
          on
          onChange={(e) => setNewRadius(Number(e.target.value))}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button style={buttonLoadingStyles(loading)} onClick={sendNewRadius} color='primary'>
          {loading ? 'Cargando...' : 'Cambiar Metros'}
        </Button>
      </div>
    </div>
  )
}

export default AddMoreKmRadious
