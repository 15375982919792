import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import PartnersController from '../controllers/PartnersController'

export const types = {
  GET_PARTNERS: 'GET_PARTNERS',
  GET_PARTNER: 'GET_PARTNER',
  SET_PARTNERS: 'SET_PARTNERS',
  SET_PARTNER: 'SET_PARTNER',
  CREATE_PARTNER: 'CREATE_PARTNER',
  UPDATE_PARTNER: 'UPDATE_PARTNER',
  ERRORS_UPDATE_PARTNER: 'ERRORS_UPDATE_PARTNER',
  GET_KM_RADIUS: 'GET_KM_RADIUS',
  SET_KM_RADIUS: 'SET_KM_RADIUS',
  UPDATE_KM_RADIUS: 'UPDATE_KM_RADIUS'
}

export const getPartners = createAsyncThunk(
  types.GET_PARTNERS,
  async ({ payload }, { dispatch }) => {
    const response = await PartnersController.getPartners(payload)
    dispatch(setPartners(response))
    return response
  }
)
export const getPartner = createAsyncThunk(
  types.GET_PARTNER,
  async ({ payload }, { dispatch }) => {
    const response = await PartnersController.getPartner(payload)
    dispatch(setPartner(response))
    return response
  }
)

export const setPartners = createAction(types.SET_PARTNERS, (partners) => ({
  payload: partners
}))

export const setPartner = createAction(types.SET_PARTNER, (partner) => ({
  payload: partner
}))

export const setKmRadius = createAction(types.SET_KM_RADIUS, (kmRadius) => ({
  payload: kmRadius
}))

export const postPartner = createAsyncThunk(
  types.CREATE_PARTNER,
  async ({ payload }, { dispatch }) => {
    const response = await PartnersController.postPartner(payload)
    return response
  }
)
export const updatePartner = createAsyncThunk(
  types.UPDATE_PARTNER,
  async ({ payload }, { dispatch }) => {
    try {
      const response = await PartnersController.updatePartner(payload)
      dispatch(setPartnerUpdateErrors(null))
      return response
    } catch (e) {
      dispatch(setPartnerUpdateErrors(e.response.data))
    }
  }
)

export const setPartnerUpdateErrors = createAction(
  types.ERRORS_UPDATE_PARTNER,
  (errors) => ({
    payload: errors
  })
)

export const getKmRadius = createAsyncThunk(
  types.GET_KM_RADIUS,
  async (_, { dispatch }) => {
    const response = await PartnersController.getKmRadius()
    dispatch(setKmRadius(response))
    return response
  }
)

export const updateKmRadius = createAsyncThunk(
  types.UPDATE_KM_RADIUS,
  async ({ payload }, { dispatch }) => {
    try {
      const response = await PartnersController.updateKmRadius(payload)
      dispatch(setKmRadius(response))
      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
)
