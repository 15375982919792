import React from 'react'
import Partners from '../components/Partners/Partners'

const PartnersPage = () => {
  return (
    <Partners />
  )
}

export { PartnersPage }
