import { createReducer } from '@reduxjs/toolkit'

import * as partnersActions from '.././actions/PartnersActions'

const initialState = {
  partners: null,
  partner: null,
  errorsUpdate: null,
  radius: null
}

const PartnersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(partnersActions.setPartners, (state, { payload }) => ({
      ...state,
      partners: payload
    }))
    .addCase(partnersActions.setPartner, (state, { payload }) => ({
      ...state,
      partner: payload
    }))
    .addCase(partnersActions.setPartnerUpdateErrors, (state, { payload }) => ({
      ...state,
      errorsUpdate: payload
    }))
    .addCase(partnersActions.setKmRadius, (state, { payload }) => ({
      ...state,
      radius: payload
    }))
})

export default PartnersReducer
