import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import './Loader.scss'
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: '2rem'
    },
    justifyContent: 'center',
    width: '100%'
  }
}))

export default function Loader () {
  const [isData, setIsData] = useState(true)
  const setTimer = () => {
    setTimeout(() => {
      setIsData(false)
    }, 15000)
  }
  const classes = useStyles()
  setTimer()
  return (
    <div className={classes.root}>
      {isData
        ? <div className='loader'></div>
        : <span style={{ color: 'gray', fontWeight: 'bold' }}>No data for table</span>}
    </div>
  )
}
