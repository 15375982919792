import { createReducer } from '@reduxjs/toolkit'

import * as dashboardActions from '.././actions/DashboardActions'

const initialState = {
  dashboard: null,
  ordersCompleted: {},
  ordersPick: {},
  pointsFinalized: {},

  dashboardSameday: null,
  ordersCompletedSameday: {},
  ordersPickSameday: {},
  pointsFinalizedSameday: {},

  dashboardExpress: null,
  ordersCompletedExpress: {},
  ordersPickExpress: {},
  pointsFinalizedExpress: {}
}

const DashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(dashboardActions.setDashboard, (state, { payload }) => ({
      ...state,
      dashboard: payload
    }))
    .addCase(dashboardActions.setDashboardSameday, (state, { payload }) => ({
      ...state,
      dashboardSameday: payload
    }))
    .addCase(dashboardActions.setDashboardExpress, (state, { payload }) => ({
      ...state,
      dashboardExpress: payload
    }))
    .addCase(dashboardActions.setPointsFinalized, (state, { payload }) => ({
      ...state,
      pointsFinalized: payload
    }))
    .addCase(
      dashboardActions.setPointsFinalizedSameday,
      (state, { payload }) => ({
        ...state,
        pointsFinalizedSameday: payload
      })
    )
    .addCase(
      dashboardActions.setPointsFinalizedExpress,
      (state, { payload }) => ({
        ...state,
        pointsFinalizedExpress: payload
      })
    )
    .addCase(dashboardActions.setOrdersCompleted, (state, { payload }) => ({
      ...state,
      ordersCompleted: payload
    }))
    .addCase(
      dashboardActions.setOrdersCompletedSameday,
      (state, { payload }) => ({
        ...state,
        ordersCompletedSameday: payload
      })
    )
    .addCase(
      dashboardActions.setOrdersCompletedExpress,
      (state, { payload }) => ({
        ...state,
        ordersCompletedExpress: payload
      })
    )
    .addCase(dashboardActions.setOrdersPick, (state, { payload }) => ({
      ...state,
      ordersPick: payload
    }))
    .addCase(dashboardActions.setOrdersPickSameday, (state, { payload }) => ({
      ...state,
      ordersPickSameday: payload
    }))
    .addCase(dashboardActions.setOrdersPickExpress, (state, { payload }) => ({
      ...state,
      ordersPickExpress: payload
    }))
})

export default DashboardReducer
