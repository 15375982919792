import React from 'react'
import Grid from '@mui/material/Grid'
import Pie from './Pie'
import moment from 'moment'
import 'moment/locale/es'
import './dashboard.scss'
import HelpModal from './HelpModal'

export default function Statistics ({ dashboard }) {
  const DATE_NOW = new Date()
  const pointsPending = () => {
    const after = dashboard?.pointsCompletedGroup?.after
    const before = dashboard?.pointsCompletedGroup?.before
    const points = dashboard?.pointsTotal
    return after + before === points ? 0 : points - (after + before)
  }
  // const completedOrders = () => {
  //   dispatch()
  // }

  // define the functionality of onClick for each indicator
  /* const nextView = () => {
    dispatch(ordersActions.setStatus({ status: "beforeYesterday" }));
    history("/dashboard/pedidos");
  };
  const nextViewPeding = () => {
    dispatch(ordersActions.setStatus({ status: "yesterday" }));
    history("/dashboard/pedidos");
  }; */
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div
      style={{
        marginLeft: '10%',
        marginRight: '10%',
        width: '80%'
      }}
    >
      <div className='statistics__header'>
        <h2>Sameday</h2>
        <p className='statistics__date'>{moment(DATE_NOW).format('LLLL')}</p>
      </div>

      <Grid container spacing={5}>
        <Grid item xs={6}>
          <div className='card'>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a las ordenes que se han completado' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Órdenes Completadas</h4>
              <hr className='divider'></hr>
              <Pie
                percentage={dashboard?.ordersCompletedPercent}
                colour='#54A268'
              />
              <h4 className='card__title card__description1'>
                {`${dashboard?.ordersCompleted} de 
                  ${dashboard?.ordersTotal}`}
              </h4>
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className='card'>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a los envíos pendientes de entregar (realizados ayer)' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Envíos pendientes</h4>
              <hr className='divider'></hr>
              <div className='fab'>
                <h2 className='fab__title'>{pointsPending()}</h2>
              </div>
              <h4 className='card__title card__description'>Despachos</h4>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
