import order from './HttpOrder'

class LoginController {
  static async login (payload) {
    try {
      const response = await order.post('/admin/login', payload.payload)
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default LoginController
