import React, { useEffect, useRef } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'
import moto from '../../assets/img/rider_disponible.png'
import motoOcupado from '../../assets/img/rider_ocupado.png'

// eslint-disable-next-line react/jsx-filename-extension
const GMap = () => {
  let unsubscribeMap = null
  const googleMapRef = useRef(null)
  let googleMap = null
  const database = getDatabase()
  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      zoom: 12,
      center: { lat: -12.09718, lng: -77.03258 }
    })
  }

  const getAllMenuItems = async () => {
    const mapFire = new window.google.maps.Map(googleMapRef.current, {
      zoom: 11,
      center: { lat: -12.09718, lng: -77.03258 },
      minZoom: -1000,
      maxZoom: 30
    })
    const popup = new window.google.maps.InfoWindow()
    const markers = []
    const dbRef = ref(database, 'partner_location_updates')
    unsubscribeMap = onValue(dbRef, function (snapshot) {
      let drivers = []
      snapshot.forEach(function (childSnapshot) {
        const driver = childSnapshot.val()
        if (Object.prototype.hasOwnProperty.call(driver, 'locked')) {
          if (driver.locked === 'activo' && driver.state !== 'offline') {
            drivers.push(childSnapshot.val())
          }
        } else {
          if (driver.state !== 'offline') {
            drivers.push(childSnapshot.val())
          }
        }
      })
      drivers = drivers.filter((item) => item.state !== 'offline')
      let icon = {}
      for (let i = 0; i < drivers.length; i++) {
        const item = drivers[i]
        if (item.state === 'busy') {
          icon = {
            url: motoOcupado,
            scaledSize: new window.google.maps.Size(30, 30),
            anchor: new window.google.maps.Point(15, 15)
          }
        } else {
          icon = {
            url: moto,
            scaledSize: new window.google.maps.Size(30, 30),
            anchor: new window.google.maps.Point(15, 15)
          }
        }
        if (markers[i]) {
          markers[i].setPosition(
            new window.google.maps.LatLng(
              Number.parseFloat(item.latittude),
              Number.parseFloat(item.longuitude)
            )
          )
          markers[i].setIcon(icon)
          markers[i].setLabel({
            text: `${item.partnerName}`,
            color: 'purple',
            backgroundColor: 'red',
            fontSize: '20px',
            fontWeight: 'bold',
            width: '10px',
            whiteSpace: 'nowrap'
          })
          item.state === 'offline' ? markers[i].setVisible(false) : markers[i].setVisible(true)

          markers[i].addListener('click', () => {
            popup.setContent(`
          <div style="height: 30px;width:80px">
            <div style="margin-bottom: -5px"> <strong>${item.partnerName} ${item.partnerLastName}</strong> </div>
          </div>
        `)
            popup.open(mapFire, markers[i])
          })
        } else {
          const marker = new window.google.maps.Marker({
            position: {
              lat: Number.parseFloat(item.latittude),
              lng: Number.parseFloat(item.longuitude)
            },
            map: mapFire,
            icon,
            label: {
              text: `${item.partnerName}`,
              color: 'purple',
              backgroundColor: 'red',
              fontSize: '20px',
              fontWeight: 'bold',
              width: '10px',
              whiteSpace: 'nowrap'
            }
          })
          marker.addListener('click', () => {
            popup.setContent(`
          <div style="height: 30px;width:80px">
            <div style="margin-bottom: -5px"> <strong>${item.partnerName}</strong> </div>
          </div>
          `)
            popup.open(mapFire, marker)
          })
          markers.push(marker)
        }
      }
    })
  }
  useEffect(() => {
    googleMap = initGoogleMap()
    const bounds = new window.google.maps.LatLngBounds()
    getAllMenuItems()
    googleMap.fitBounds(bounds) // the map to contain all markers

    return () => unsubscribeMap()
  }, [])

  return (
    <div
      className='gmap'
      style={{
        width: '100%',
        height: '700px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <div ref={googleMapRef} style={{ width: '1000px', height: '90%' }} />
    </div>
  )
}
export default GMap
