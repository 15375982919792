import React from 'react'
import { withStyles, makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import { orderSelector } from '../../selectors/OrderSelectors'
import * as orderActions from '../../actions/OrderActions'
import getDistance from 'geolib/es/getDistance'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
    color: 'grey'
  }
})

const useStyles = makeStyles(() => ({
  buttonBlue: {
    backgroundColor: '#2e0049',
    color: 'white'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  color: {
    color: '#3F4D55',
    padding: '5px 20px',
    '& span': {
      marginTop: '15px',
      display: 'flex',
      justifyContent: 'center'
    },
    '& h2': {
      margin: 0
    }
  },
  button: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px 10px 20px 10px'
  }
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
        >
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

const DeletePoint = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showDelete, handleCloseDelete, point } = props
  const draft = useSelector(orderSelector)

  const handleDeletePoint = () => {
    const points = draft.points
    const selectFilter = points.filter((item, i) => item._id !== point._id)
    const origin = selectFilter[0]
    let maxDistance = 0
    for (let i = 1; i < selectFilter.length; i++) {
      const detail = selectFilter[i]
      const distance = getDistance(
        { latitude: origin.lat, longitude: origin.lng },
        { latitude: detail.lat, longitude: detail.lng }
      )
      maxDistance = maxDistance + distance
      if (distance > maxDistance) {
        maxDistance = distance
      }
    }
    const order = {
      id: draft._id,
      service: draft.service,
      vehicle: draft.vehicle,
      points: selectFilter,
      customer: draft.customer
    }
    const verification = {
      card_id: '',
      distance: maxDistance
    }
    const payload = { order, verification }
    dispatch(orderActions.updatePoints({ payload }))
    handleCloseDelete()
    NotificationManager.success('Punto eliminado exitosamente', 'Punto')
  }
  return (
    <div>
      <Dialog
        onClose={handleCloseDelete}
        aria-labelledby='customized-dialog-title'
        open={showDelete}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleCloseDelete}>
          <NotListedLocationIcon color='secondary' style={{ fontSize: 100 }} />
        </DialogTitle>
        <div className={classes.color}>
          <h2>¿Estás seguro de eliminar este punto?</h2>
          <span>No podrás revertir esta acción</span>
        </div>

        <div className={classes.button}>
          <Button
            variant='contained'
            autoFocus
            onClick={handleDeletePoint}
            className={classes.buttonBlue}
          >
            Sí, cancelar
          </Button>
          <Button
            variant='contained'
            autoFocus
            onClick={handleCloseDelete}
            color='secondary'
          >
            No
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
export default DeletePoint
