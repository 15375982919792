export const orderDetail = {
  formatService: {
    programmed: 'NextDay',
    sameday: 'SameDay',
    express: 'Express'
  },
  statusFormat: {
    pending: '#C4C4C4',
    confirmed: '#1976d2',
    sending: 'rgba(183,121,31)',
    canceled: 'red',
    completed: 'green',
    arrived: 'rgba(183,121,31)',
    reserved: '#5D1F82'
  },
  colorStatus: {
    pending: 'black',
    confirmed: 'white',
    sending: 'white',
    canceled: 'white',
    completed: 'white',
    arrived: 'white',
    reserved: 'white'
  },
  formatStatus: {
    pending: 'Pendiente',
    completed: 'Completado',
    confirmed: 'Asignado',
    canceled: 'Cancelado',
    arrived: 'En Ruta',
    sending: 'En Ruta',
    reserved: 'Reservado'
  }
}
export const truncateStrings = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...'
  }
  return str
}

export const adviserFormatToString = {
  0: 'Sin asginar',
  1: 'Alessandra Norabuena',
  2: 'Raisa Torres'
}

export const formatPayment = {
  credit: 'Crédito',
  card: 'Tarjeta'
}
