import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { clientsSelector } from '../../selectors/ClientsSelectors'
import * as clientsActions from '../../actions/ClientsActions'
import * as ordersActions from '../../actions/OrdersActions'

export default function SelectCustomer (props) {
  const [value, setValue] = React.useState(null)
  // const [inputValue, setInputValue] = React.useState("");
  const dispatch = useDispatch()
  const data = useSelector(clientsSelector)
  const dispatchExpress = (payloadData) => props.service === 'express'
    ? dispatch(ordersActions.getOrdersExpress({ payload: payloadData }))
    : dispatch(ordersActions.getOrdersFilters({ payload: payloadData }))
  useEffect(() => {
    const search = ''
    const company = ''
    dispatch(
      clientsActions.getClients({ payload: { page: 1, company, data: search } })
    )
  }, [dispatch])
  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        options={data?.docs}
        getOptionLabel={(option) =>
          `${
            option.super
              ? `${option.company.name} | ${option.user.name.first} ${option.user.name.last}`
              : `${option.user.name.first} ${option.user.name.last}`
          }`
        }
        id='movies'
        value={value}
        onChange={(event, newValue) => {
          const payloadFinal = {
            page: 1,
            data: {
              customer: ''
            }
          }
          if (newValue) {
            setValue(newValue)
            payloadFinal.data.customer = newValue.id
            dispatchExpress(payloadFinal)
          }
          props.payloadFunc(payloadFinal)
        }}
        onInputChange={(event) => {
          // setInputValue(newInputValue);
          const search = `search=${event.target.value}`
          const company = ''
          dispatch(
            clientsActions.getClients({
              payload: { page: 1, company, data: search }
            })
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: 0 }}
            label='Clientes'
            margin='normal'
            variant='outlined'
          />
        )}
      />
    </div>
  )
}
