
import isPointInPolygon from 'geolib/es/isPointInPolygon'
import client from '../controllers/HttpOrder'

let business

export async function getBusiness () {
  business = business || (await client.get('/business')).data
  return business
}

export async function getServiceZoneByPoint (serviceId, point) {
  const business = await getBusiness()
  const { services, districtsMap } = business
  const districts = Object.values(districtsMap)
  const service = services.find((s) => s.id === serviceId)
  const district = districts.find((d) => isPointInPolygon(point, d.polygon))
  if (!district) return null
  const zone = service.zones.find((z) => z.districts.includes(district.name))
  return zone
}
