import React, { forwardRef } from 'react'
import OrderInfo from './OrderInfo'
import RiderInfo from './RiderInfo'
import './MiniDetail.scss'
import { Grid } from '@mui/material'
import PointsInfo from './PointsInfo'

const mdContainer = {
  marginTop: '70px'
}
const MiniDetail = forwardRef((props, ref) => {
  // const mapRidersRef = useRef();
  // useImperativeHandle(ref, () => ({
  //   showAlert() {
  //     mapRidersRef.current.showAllRiders();
  //   },
  // }));
  const data = props.data

  const customOrderData = {
    _id: data?._id,
    customer: { ...data?.customer },
    partner: { ...data?.partner },
    service: data?.service,
    status: data?.status,
    amount: data?.amount,
    partnerProfit: data?.partnerProfit,
    distance: data?.distance,
    points: data?.points
  }

  return (
    <div>
      <Grid style={mdContainer} container spacing={2}>
        <Grid item xs={12} sm={4}>
          <OrderInfo order={customOrderData} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RiderInfo order={customOrderData} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PointsInfo order={customOrderData} />
        </Grid>
      </Grid>
    </div>
  )
})

export default MiniDetail
