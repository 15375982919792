import order from './HttpOrder'
import api from './HttpApi'

class OrderController {
  static async getOrder (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.get(`/admin/orders/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updatePoint (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await api.patch('/admin/point', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async cancelOrder (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.put(`/admin/orders/${payload.id}/cancel`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updateOrder (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.put(`/admin/orders/${payload.order.id}/update`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async assignPartner (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.post(
        `/admin/orders/${payload.orderId}/assign`,
        payload.partner,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getZones () {
    try {
      const token = localStorage.getItem('token')
      const response = await order.get('/zones', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updatePointState (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.put(
        `/admin/orders/${payload.id}/point/${payload.pointId}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default OrderController
