import { createReducer } from '@reduxjs/toolkit'

import * as ordersActions from '.././actions/OrdersActions'

const initialState = {
  orders: null,
  status: '',
  express: null
}

const OrdersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ordersActions.setOrders, (state, { payload }) => ({
      ...state,
      orders: payload
    }))
    .addCase(ordersActions.setStatus, (state, { payload }) => ({
      ...state,
      status: payload.status
    }))
    .addCase(ordersActions.setExpress, (state, { payload }) => ({
      ...state,
      express: payload
    }))
})

export default OrdersReducer
