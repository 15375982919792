import React, { useEffect } from 'react'
import Map from './map'
import { Grid } from '@mui/material'

const mdContainer = {
  margin: '80px 0 10px 0',
  maxHeight: '290px'
}

const RealTime = () => {
  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === 'object' &&
      typeof window.google.maps === 'object'
    ) {
      callback()
    } else {
      const googleMapScript = document.createElement('script')
      window.document.body.appendChild(googleMapScript)
      googleMapScript.addEventListener('load', callback)
    }
  }
  useEffect(() => {
    loadGoogleMapScript(() => {})
  }, [])
  return (
    <div>
      <Grid style={mdContainer} container spacing={2}>
        <Grid item xs={12}>
          <Map />
        </Grid>
      </Grid>
    </div>
  )
}

export default RealTime
