import { createSelector } from '@reduxjs/toolkit'

export const dashboardStateSelector = createSelector(
  (state) => state.dashboard,
  (dashboard) => dashboard
)

export const dashboardSamedayStateSelector = createSelector(
  (state) => state.dashboardSameday,
  (dashboardSameday) => dashboardSameday
)

export const dashboardExpressStateSelector = createSelector(
  (state) => state.dashboardExpress,
  (dashboardExpress) => dashboardExpress
)

export const dashboardSelector = createSelector(
  dashboardStateSelector,
  (state) => state.dashboard
)

export const dashboardSamedaySelector = createSelector(
  dashboardStateSelector,
  (state) => state.dashboardSameday
)

export const dashboardExpressSelector = createSelector(
  dashboardStateSelector,
  (state) => state.dashboardExpress
)

export const pointsFinalizedSelector = createSelector(
  dashboardStateSelector,
  (state) => state.pointsFinalized
)

export const pointsFinalizedSamedaySelector = createSelector(
  dashboardStateSelector,
  (state) => state.pointsFinalizedSameday
)

export const pointsFinalizedExpressSelector = createSelector(
  dashboardStateSelector,
  (state) => state.pointsFinalizedExpress
)

export const ordersCompletedSelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersCompleted
)

export const ordersCompletedSamedaySelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersCompletedSameday
)

export const ordersCompletedExpressSelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersCompletedExpress
)

export const ordersPickSelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersPick
)

export const ordersPickSamedaySelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersPickSameday
)

export const ordersPickExpressSelector = createSelector(
  dashboardStateSelector,
  (state) => state.ordersPickExpress
)
