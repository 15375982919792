import order from './HttpApi'
import docClient from './HttpOrder'
class ClientsController {
  static async getClients (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.get(
        `/customer/?limit=10&page=${payload.page}&${
          payload.company ? `type=${payload.company}&${payload.data}` : `${payload.data}`
        }
      `,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getClient (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await docClient.get(`/admin/customers/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updateClient (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await docClient.put(`/admin/customers/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default ClientsController
