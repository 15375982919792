import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import OrdersController from '../controllers/OrdersController'

export const types = {
  GET_ORDERS: 'GET_ORDERS',
  SET_ORDERS: 'SET_ORDERS',
  POST_FILTER_ORDERS: 'POST_FILTER_ORDERS',
  SET_STATUS: 'SET_STATUS',
  GET_EXPRESS: 'GET_EXPRESS',
  SET_EXPRESS: 'SET_EXPRESS'
}

export const getOrders = createAsyncThunk(
  types.GET_ORDERS,
  async (_, { dispatch }) => {
    const response = await OrdersController.getOrders()
    dispatch(setOrders(response))
    return response
  }
)

export const getOrdersFilters = createAsyncThunk(
  types.POST_FILTER_ORDERS,
  async ({ payload }, { dispatch }) => {
    const response = await OrdersController.getOrdersFilters(payload)
    dispatch(setOrders(response))
    return response
  }
)

export const getOrdersExpress = createAsyncThunk(
  types.GET_EXPRESS,
  async ({ payload }, { dispatch }) => {
    const response = await OrdersController.getExpress(payload)
    dispatch(setExpress(response))
    return response
  }
)

export const setOrders = createAction(types.SET_ORDERS, (orders) => ({
  payload: orders
}))

export const setStatus = createAction(types.SET_STATUS, (status) => ({
  payload: status
}))

export const setExpress = createAction(types.SET_EXPRESS, (express) => ({
  payload: express
}))
