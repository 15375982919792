import { createSelector } from '@reduxjs/toolkit'

export const partnersStateSelector = createSelector(
  (state) => state.partners,
  (partners) => partners
)

export const partnersSelector = createSelector(
  partnersStateSelector,
  (state) => state.partners
)

export const partnerSelector = createSelector(
  partnersStateSelector,
  (state) => state.partner
)

export const errorsUpdateSelector = createSelector(
  partnersStateSelector,
  (state) => state.errorsUpdate
)

export const radiusSelector = createSelector(
  partnersStateSelector,
  (state) => state.radius
)
