import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'
import { configureStore } from '@reduxjs/toolkit'
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
})
export const store = configureStore(
  { reducer: rootReducer },
  composeEnhancers(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
)
