import client from './HttpApi'

class DashboardController {
  static async getDashboard (date, token) {
    try {
      const response = await client.get('/report-day', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getDashboardSameday (date, token) {
    try {
      const response = await client.get('/report-same-day', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getDashboardExpress (date, token) {
    try {
      const response = await client.get('/report-express', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default DashboardController
