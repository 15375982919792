export const chipStyles = {
  width: '100%',
  textAlign: 'center',
  borderRadius: '4px',
  padding: '4px',
  margin: 0
}

export const iconStyles = {
  height: '20px',
  width: '20px',
  color: 'grey'
}
