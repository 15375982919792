import { createReducer } from '@reduxjs/toolkit'
import * as settingsActions from '../actions/SettingsActions'

const initialState = {
  services: {}
}

const SettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(settingsActions.setServices, (state, { payload }) => ({
    ...state,
    services: payload
  }))
})

export default SettingsReducer
