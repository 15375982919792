import React from 'react'
import Login from '../components/Login/Login'

const LoginPage = () => {
  return (
    <Login />
  )
}

export { LoginPage }
