import { Card, IconButton } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { makeStyles } from '@mui/styles'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { orderDetail } from '../../../assets/js/formaters'
import './MiniDetail.scss'
const useStyles = makeStyles((theme) => ({
  button: {
    width: '70%',
    height: '40px',
    backgroundColor: '#ECC94B',
    fontWeight: 'bold',
    color: '#3F4D55'
  },
  copyButton: {
    color: '#2e0049',
    fontSize: '1.1em'
  },
  card: {
    padding: '10px',
    height: '25vh',
    overflow: 'auto'
  }
}))

const OrderInfo = ({ order }) => {
  const classes = useStyles()
  return (
    <>
      {order !== null && (
        <Card className={classes.card}>
          <div className='md__header'>
            <h4>Cliente</h4>
          </div>
          <div>
            <hr></hr>
          </div>
          <div>
            <div className='md__info'>
              <span>Cliente: </span>{' '}
              <span>{order?.customer?.user?.name?.first}</span>
            </div>
            <div className='md__info'>
              <span>ID de Pedido: </span>
              <span id='order__id'>
                {order?._id}
                <IconButton
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        document.querySelector('span#order__id').textContent
                      )
                      .then(
                        NotificationManager.success('ID copiado exitosamente')
                      )
                  }
                  title='Copiar'
                  size='small'
                  style={{ marginLeft: '10px' }}
                >
                  <FileCopyIcon className={classes.copyButton} />
                </IconButton>
              </span>
            </div>
            <div className='md__info'>
              <span>Servicio: </span>{' '}
              <span>{orderDetail.formatService[order?.service]}</span>
            </div>
            <div className='md__info'>
              <span>Teléfono: </span>{' '}
              <span id='phone'>
                {order?.customer?.phone}
                <IconButton
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        document.querySelector('span#phone').textContent
                      )
                      .then(
                        NotificationManager.success(
                          'Teléfono copiado exitosamente'
                        )
                      )
                  }
                  title='Copiar'
                  size='small'
                  style={{ marginLeft: '10px' }}
                >
                  <FileCopyIcon className={classes.copyButton} />
                </IconButton>
              </span>
            </div>
          </div>
        </Card>
      )}
    </>
  )
}

export default OrderInfo
