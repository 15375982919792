import OrderController from '../../controllers/OrderController'

export const progressBarAfter = (after, before, total) => {
  if (!before || before <= 0) {
    if (after === total) {
      return '10px'
    } else if (after || after > 0) {
      return '10px 0px 0px 10px'
    }
  } else if (total === after + before) {
    return '0px 10px 10px 0px'
  }
}
export const progressBarBefore = (before, total) => {
  if (before === total) {
    return '10px'
  } else if (before && before > 0) {
    return '10px 0px 0px 10px'
  }
}
export const progressBarTotal = (after, before) => {
  if (!after && after <= 0 && !before && before <= 0) {
    return '10px'
  } else {
    return '0px 10px 10px 0px'
  }
}
export const selectOneOrder = async (id) => {
  return await OrderController.getOrder({
    id
  })
}
export const ORANGE_COLOR = '#E19829'
export const GREEN_COLOR = '#54A268'
export const GRAY_COLOR = '#F0F0F0'
