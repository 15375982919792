import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import './partners.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { partnersSelector } from '../../selectors/PartnersSelectors'
import * as partnersActions from '../../actions/PartnersActions'
import 'moment/locale/es'
import Loader from '../Modals/Loader'
import Paginator from '../Modals/Paginator'
import { CommonTable, PARTNER_HEADERS } from '../Common'
import { renderTable } from '../../assets/js/helpers'

const Partners = () => {
  const dispatch = useDispatch()
  const data = useSelector(partnersSelector)
  const history = useNavigate()
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const createPartner = () => {
    history('/dashboard/crear-mensajero')
  }
  const handleSearch = (event) => {
    setSearch(event.target.value)
    const payloadFinal = {
      page: 1,
      data: `search=${event.target.value}`
    }
    dispatch(partnersActions.getPartners({ payload: payloadFinal }))
  }

  useEffect(() => {
    const payloadFinal = {
      page: 1,
      data: ''
    }
    dispatch(partnersActions.getPartners({ payload: payloadFinal }))
  }, [dispatch])

  const handleChangePage = (event, value) => {
    setPage(value)
    const payloadFinal = {
      page: value,
      data: search ? `search=${search}` : ''
    }
    if (value <= data?.totalPages) {
      dispatch(partnersActions.getPartners({ payload: payloadFinal }))
    }
  }
  return (
    <div
      style={{
        marginLeft: '5%',
        marginRight: '5%',
        width: '90%',
        marginTop: '60px'
      }}
    >
      <div className='header__titles'>
        <h2>Lista de Mensajeros</h2>
      </div>
      <div className='header__partners'>
        <div>
          <TextField
            id='outlined-basic'
            label='Buscar'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearch}
            style={{ width: 220 }}
          />
        </div>
        <Button
          variant='contained'
          size='small'
          style={{
            backgroundColor: '#2e0049',
            color: 'white'
          }}
          onClick={createPartner}
        >
          Añadir nuevo mensajero
        </Button>
      </div>
      <div className='content__table'>
        {!data ? (
          <Loader />
        ) : (
          renderTable(
            data.docs.length,
            <CommonTable
              headers={PARTNER_HEADERS}
              docs={data?.docs}
              type='partner'
            />
          )
        )}
        <Paginator data={data} page={page} handleChange={handleChangePage} />
      </div>
    </div>
  )
}
export default Partners
