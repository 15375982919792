import React from 'react'
import Dashboard from '../components/Dashboard/Dashboard'

const DashboardPage = () => {
  return (
    <Dashboard />
  )
}

export { DashboardPage }
