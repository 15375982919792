import React from 'react'
import Grid from '@mui/material/Grid'
import Pie from './Pie'
import moment from 'moment'
import 'moment/locale/es'
import './dashboard.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as ordersActions from '../../actions/OrdersActions'
import HelpModal from './HelpModal'

export default function Statistics ({ dashboard }) {
  const DATE_NOW = new Date()
  const dispatch = useDispatch()
  const history = useNavigate()

  // const completedOrders = () => {
  //   dispatch()
  // }
  const nextView = () => {
    dispatch(ordersActions.setStatus({ status: 'beforeYesterday' }))
    history('/dashboard/pedidos')
  }
  const nextViewPeding = () => {
    dispatch(ordersActions.setStatus({ status: 'yesterday' }))
    history('/dashboard/pedidos')
  }
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div
      style={{
        marginLeft: '10%',
        marginRight: '10%',
        width: '80%'
      }}
    >
      <div className='statistics__header'>
        <h2>Nextday</h2>
        <p className='statistics__date'>{moment(DATE_NOW).format('LLLL')}</p>
      </div>

      <Grid container spacing={5}>
        <Grid item xs={3}>
          <div className='card' onClick={nextView}>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a las ordenes que se han completado' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Órdenes Completadas</h4>
              <hr className='divider'></hr>
              <Pie
                percentage={dashboard?.ordersCompletedPercent}
                colour='#54A268'
              />
              <h4 className='card__title card__description1'>
                {`${dashboard?.ordersCompleted} de 
                  ${dashboard?.ordersTotal}`}
              </h4>
            </div>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className='card' onClick={nextView}>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a los envíos pendientes de entregar (realiazados hace 2 días)' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Envíos pendientes de ayer</h4>
              <hr className='divider'></hr>
              <div className='fab'>
                <h2 className='fab__title'>
                  {dashboard?.countPointsPendingYesterDay}
                </h2>
              </div>
              <h4 className='card__title card__description'>
                Pendientes de ayer
              </h4>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className='card' onClick={nextViewPeding}>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a los envíos pendientes de entregar (realizados ayer)' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Envíos pendientes</h4>
              <hr className='divider'></hr>
              <div className='fab'>
                <h2 className='fab__title'>{dashboard?.pointsPending}</h2>
              </div>
              <h4 className='card__title card__description'>Despachos</h4>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className='card'>
            <div className='help__text'>
              <HelpModal message='Este espacio hace referencia a las devoluciones de destinos en específico' />
            </div>
            <div className='card__content'>
              <h4 className='card__title'>Devoluciones pendientes</h4>
              <hr className='divider'></hr>
              <div className='fab'>
                <h2 className='fab__title'>{dashboard?.pointsReturnPending}</h2>
              </div>
              <h4 className='card__title card__description'>
                {dashboard?.pointsReturnPending} pendientes
              </h4>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
