import React from 'react'
import { makeStyles } from '@mui/styles'
import Pagination from '@mui/lab/Pagination'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: '3rem'
    }
  }
}))

export default function Paginator ({ data, page, handleChange }) {
  const classes = useStyles()
  return (
    <div>
      {data?.totalPages > 0 && (
        <div className={classes.root}>
          <Pagination
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center'
            }}
            count={data?.totalPages}
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  )
}
