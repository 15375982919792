import { useState } from 'react'
import {
  Link,
  IconButton,
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Box
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import logo from '../../assets/img/logo.png'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Alert } from '@mui/lab'
import { useDispatch } from 'react-redux'
import * as loginActions from '../../actions/LoginActions'
import { useNavigate } from 'react-router-dom'
import LoopIcon from '@mui/icons-material/Loop'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2% 0px'
  },
  img__style: {
    width: '200px'
  },
  margin: {
    margin: '2rem'
  },
  withoutLabel: {
    marginTop: '2rem'
  },
  textField: {
    backgroundColor: '#',
    width: '40ch'
  },
  header__style: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '15px',
    '& div': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    '& h2': {
      textAlign: 'center',

      padding: '5px',
      width: '80%',
      margin: '2% 8.7% 0% 8.7%',

      color: '#2e0049'
    }
  },
  foot: {
    color: '#2e0049',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 35px',
    marginBottom: '20px'
  },
  login__button: {
    marginTop: '1%',
    display: 'flex',
    justifyContent: 'center'
  },
  login__header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '96%',
    padding: '0% 2%',
    marginTop: '1%',
    '& div': {
      marginTop: '5px'
    },
    '& h1': {
      margin: 0
    }
  },
  foot__letter: {
    position: 'fixed',
    bottom: '20px',
    padding: '0% 2%'
  }
}))
export default function Login () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()
  const [form, setForm] = useState({
    password: '',
    email: '',
    showPassword: false
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword })
    setIsLoading(false)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const sendLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await dispatch(loginActions.login({ payload: form }))
      if (localStorage.getItem('token')) history('/dashboard/inicio')
      setIsLoading(false)
    } catch (err) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const StyledTextField = styled(TextField)({
    width: '100% !important'
  })
  const Spinner = styled(LoopIcon)({
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    },
    animation: 'spin 1s infinite ease'
  })
  const isDisabledLogin = !form.password && !form.email
  return (
    <>
      <div className={classes.login__header}>
        <h1>
          {' '}
          <FiberManualRecordIcon style={{ color: '#86ea5b' }} /> Login
        </h1>
        <div>
          Si necesitas ayuda con tu cuenta, escribe a{' '}
          <Link href='mailto:soporte@nirex.pe'>soporte@nirex.pe</Link>
        </div>
      </div>
      <div className={classes.logo}>
        {' '}
        <img className={classes.img__style} src={logo} alt='logo nirex' />{' '}
      </div>
      <div className={classes.root}>
        <Card>
          <div className={classes.header__style}>
            <h2>Bienvenido al Administrador</h2>
          </div>
          <CardContent>
            {error && (
              <Alert severity='error'>Error revisar los datos ingresados sean correctos</Alert>
            )}
            <Box
              component='div'
              flex
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              sx={{ width: '100%' }}
            >
              <StyledTextField
                id='email'
                value={form.email}
                onChange={handleChange('email')}
                InputProps={{ endAdornment: <InputAdornment position='end'>@</InputAdornment> }}
                variant='outlined'
                label='Email'
              />
              <div style={{ height: '10px' }}></div>
              <StyledTextField
                id='password'
                variant='outlined'
                type={form.showPassword ? 'text' : 'password'}
                value={form.password}
                onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {form.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label='Password'
              />
            </Box>
          </CardContent>
          <div className={classes.foot}>
            <div>
              <Checkbox
                defaultChecked
                style={{ color: '#2e0049', padding: '0px', marginRight: '5px' }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <span>Recordarme</span>
            </div>
          </div>
        </Card>
      </div>
      <div className={classes.login__button}>
        <Button
          onClick={sendLogin}
          variant='contained'
          style={
            isDisabledLogin || isLoading
              ? { backgroundColor: '#DFDCDB', color: 'white', cursor: 'not-allowed' }
              : { backgroundColor: '#2e0049', color: 'white' }
          }
          disabled={isDisabledLogin || isLoading}
        >
          {isLoading ? <Spinner /> : 'Iniciar Sesión'}
        </Button>
      </div>
      <div className={classes.foot__letter}>
        Plataforma de uso interno de Nirex. Para realizar un pedido, visita{' '}
        <Link href='https://nirex.la'>nirex.la</Link>
      </div>
    </>
  )
}
