import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ClientsController from '../controllers/ClientsController'

export const types = {
  GET_CLIENTS: 'GET_CLIENTS',
  GET_CLIENT: 'GET_CLIENT',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_CLIENT: 'SET_CLIENT',
  UPDATE_CLIENT: 'UPDATE_CLIENT'
}

export const getClients = createAsyncThunk(
  types.GET_CLIENTS,
  async ({ payload }, { dispatch }) => {
    const response = await ClientsController.getClients(payload)
    dispatch(setClients(response))
    return response
  }
)

export const getClient = createAsyncThunk(
  types.GET_CLIENT,
  async ({ payload }, { dispatch }) => {
    const response = await ClientsController.getClient(payload)
    dispatch(setClient(response))
    return response
  }
)
export const setClients = createAction(types.SET_CLIENTS, (clients) => ({
  payload: clients
}))

export const setClient = createAction(types.SET_CLIENT, (client) => ({
  payload: client
}))

export const updateClient = createAsyncThunk(
  types.UPDATE_CLIENT,
  async ({ payload }, { dispatch }) => {
    const response = await ClientsController.updateClient(payload)
    return response
  }
)
