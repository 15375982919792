import axios from 'axios'

const client = axios.create({
  // cambiar a producción cuándo ya esté probado
  baseURL: process.env.REACT_APP_NIREX_API_URL + '/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
})

export default client
