import { createSelector } from '@reduxjs/toolkit'

export const orderStateSelector = createSelector(
  (state) => state.order,
  (order) => order
)

export const orderSelector = createSelector(
  orderStateSelector,
  (state) => state.order
)

export const zonesSelector = createSelector(
  orderStateSelector,
  (state) => state.zones
)
