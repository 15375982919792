import { createReducer } from '@reduxjs/toolkit'

import * as orderActions from '.././actions/OrderActions'

const initialState = {
  order: null,
  zones: []
}

const OrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(orderActions.setOrder, (state, { payload }) => ({
      ...state,
      order: payload
    }))
    .addCase(orderActions.setZones, (state, { payload }) => ({
      ...state,
      zones: payload
    }))
})

export default OrderReducer
