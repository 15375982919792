import { createSelector } from '@reduxjs/toolkit'

export const clientsStateSelector = createSelector(
  (state) => state.clients,
  (clients) => clients
)

export const clientsSelector = createSelector(
  clientsStateSelector,
  (state) => state.clients
)

export const clientSelector = createSelector(
  clientsStateSelector,
  (state) => state.client
)
