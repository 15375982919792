import { Card } from '@mui/material'
import React from 'react'
import { orderDetail } from '../../../assets/js/formaters'
const PointsInfo = ({ order }) => {
  return (
    <Card style={{ padding: '10px', height: '25vh', overflow: 'auto' }}>
      <div>
        <div>
          <div className='md__header'>
            <h4>Orden</h4>
            <div
              className=''
              style={{
                backgroundColor: orderDetail.statusFormat[order?.status],
                width: 80,
                textAlign: 'center',
                borderRadius: '4px',
                color: orderDetail.colorStatus[order?.status],
                padding: '4px',
                fontSize: '12px'
              }}
            >
              {orderDetail.formatStatus[order?.status]}
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className='md__info'>
            <span>Precio: </span>{' '}
            <span>{`S/. ${(order?.amount / 100).toFixed(2)}`}</span>
          </div>
          <div className='md__info'>
            <span>Distancia: </span>{' '}
            <span>{(Number(order?.distance) / 1000).toFixed(2)} km</span>
          </div>
          <div className='md__info'>
            <span>Cantidad de destinos: </span>
            <span>{order?.points?.length && order?.points?.length - 1}</span>
          </div>
          <div className='md__info'>
            <span>Origen: </span>
            <span style={{ textAlign: 'right' }}>
              {order?.points?.length ? order?.points[0]?.address : ''}
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {order?.points?.length > 0 &&
              order?.points?.map((points, index) => {
                if (index > 0) {
                  return (
                    <div
                      style={{
                        width: '100%'
                      }}
                      className='md__info'
                      key={index}
                    >
                      <span style={{ textAlign: 'right' }}>Destino {index}: </span>
                      <span style={{ textAlign: 'right' }}>{points?.address}</span>
                    </div>
                  )
                }
              })}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PointsInfo
