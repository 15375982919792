import React from 'react'
import RealTime from '../components/Map/RealTime'

const MapPage = () => {
  return (
    <RealTime />
  )
}

export { MapPage }
