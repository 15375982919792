import client from './HttpApi'

class SettingsController {
  static async getServices () {
    const token = localStorage.getItem('token')
    try {
      const response = await client.get('/available-services', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async updateServices (payload) {
    const token = localStorage.getItem('token')
    try {
      const response = await client.post('/available-services/edit', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}
export default SettingsController
