import React from 'react'
import Grid from '@mui/material/Grid'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import './dashboard.scss'
import LegendTimeLine from './LegendTimeLine'
import
{
  progressBarAfter,
  progressBarBefore,
  progressBarTotal,
  ORANGE_COLOR,
  GREEN_COLOR,
  GRAY_COLOR
}
  from '../../assets/js/setters'

export default function Resume ({
  dashboard,
  pointsFinalized,
  ordersCompleted,
  ordersPick
}) {
  return (
    <div
      style={{
        marginLeft: '10%',
        marginRight: '10%',
        width: '80%'
      }}
    >
      <div className='resume'>
        <h2>Linea de tiempo</h2>
      </div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <LegendTimeLine />
        </Grid>
        <Grid item xs={12}>
          <div className='resume__card'>
            <div className='resume__title'>
              <span className='title'>Órdenes recogidas</span>
              <p
                title='Tiempo límite'
                style={{ marginRight: '103px' }}>
                <AccessTimeIcon />
                <span style={{ marginTop: '3px', position: 'absolute' }}>
                  12:00 pm
                </span>
              </p>
            </div>
            <div className='progress'>
              <div
                role='progressbar'
                style={{
                  width: `${ordersPick?.before}%`,
                  color: 'white',
                  backgroundColor: GREEN_COLOR,
                  borderRadius: progressBarBefore(dashboard?.ordersPickup.before,
                    dashboard?.ordersTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersPickup.before > 0 &&
                <span>{dashboard?.ordersPickup.before}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${ordersPick?.after}%`,
                  color: 'white',
                  backgroundColor: ORANGE_COLOR,
                  borderRadius: progressBarAfter(dashboard?.ordersPickup.after,
                    dashboard?.ordersPickup.before, dashboard?.ordersTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersPickup.after > 0 &&
                <span>{dashboard?.ordersPickup.after}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${100 - ordersPick?.after + ordersPick?.before}%`,
                  backgroundColor: GRAY_COLOR,
                  color: '#3f4d55',
                  borderRadius: progressBarTotal(dashboard?.ordersPickup.after,
                    dashboard?.ordersPickup.before),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersTotal - (
                  dashboard?.ordersPickup.after +
                  dashboard?.ordersPickup.before
                ) > 0 &&
                <span>
                  {dashboard?.ordersTotal - (
                    dashboard?.ordersPickup.after +
                  dashboard?.ordersPickup.before)}
                </span>}
              </div>
            </div>

            <div className='card__footer'>
              <div> Total: <span>{dashboard?.ordersTotal}</span> </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='resume__card'>
            <div className='resume__title'>
              <span className='title'>Órdenes completadas</span>
              <span>
                <p
                  title='Tiempo límite'
                  style={{ marginRight: '94px' }}>
                  <AccessTimeIcon />
                  <span style={{ marginTop: '3px', position: 'absolute' }}>
                    7:00 pm
                  </span>
                </p>
              </span>
            </div>
            <div className='progress'>
              <div
                role='progressbar'
                style={{
                  width: `${ordersCompleted?.before}%`,
                  color: 'white',
                  backgroundColor: GREEN_COLOR,
                  borderRadius:
                    progressBarBefore(dashboard?.ordersCompletedGroup.before,
                      dashboard?.ordersTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersCompletedGroup.before > 0 &&
                  <span>{dashboard?.ordersCompletedGroup.before}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${ordersCompleted?.after}%`,
                  color: 'white',
                  backgroundColor: ORANGE_COLOR,
                  borderRadius: progressBarAfter(dashboard?.ordersCompletedGroup.after,
                    dashboard?.ordersCompletedGroup.before, dashboard?.ordersTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersCompletedGroup.after > 0 &&
                  <span>{dashboard?.ordersCompletedGroup.after}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${
                    100 - ordersCompleted?.before + ordersCompleted?.after
                  }%`,
                  backgroundColor: GRAY_COLOR,
                  color: '#3f4d55',
                  borderRadius: progressBarTotal(dashboard?.ordersCompletedGroup.after,
                    dashboard?.ordersCompletedGroup.before),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.ordersTotal -
                  (dashboard?.ordersCompletedGroup.before +
                    dashboard?.ordersCompletedGroup.after) > 0 &&
                    <span>{dashboard?.ordersTotal -
                    (dashboard?.ordersCompletedGroup.before +
                      dashboard?.ordersCompletedGroup.after)}</span>}
              </div>
            </div>
            <div className='card__footer'>
              <div> Total: <span>{dashboard?.ordersTotal}</span> </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='resume__card'>
            <div className='resume__title'>
              <span className='title'>Envíos completados</span>
              <span>
                <p
                  title='Tiempo límite'
                  style={{ marginRight: '94px' }}>
                  <AccessTimeIcon />
                  <span style={{ marginTop: '3px', position: 'absolute' }}>
                    7:00 pm
                  </span>
                </p>
              </span>
            </div>
            <div className='progress'>
              <div
                role='progressbar'
                style={{
                  width: `${(pointsFinalized?.before)}%`,
                  color: 'white',
                  backgroundColor: GREEN_COLOR,
                  borderRadius: progressBarBefore(dashboard?.pointsCompleted.before,
                    dashboard?.pointsTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.pointsCompleted.before > 0 &&
                <span>{dashboard?.pointsCompleted.before}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${(pointsFinalized?.after)}%`,
                  color: 'white',
                  backgroundColor: ORANGE_COLOR,
                  borderRadius: progressBarAfter(dashboard?.pointsCompleted.after,
                    dashboard?.pointsCompleted.before, dashboard?.pointsTotal),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.pointsCompleted.after > 0 &&
                <span>{dashboard?.pointsCompleted.after}</span>}
              </div>
              <div
                role='progressbar'
                style={{
                  width: `${
                    100 - (pointsFinalized?.before + pointsFinalized?.after)
                  }%`,
                  backgroundColor: GRAY_COLOR,
                  color: '#3f4d55',
                  borderRadius: progressBarTotal(dashboard?.pointsCompleted.before,
                    dashboard?.pointsCompleted.after),
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                {dashboard?.pointsTotal - (
                  dashboard?.pointsCompleted.before +
                  dashboard?.pointsCompleted.after
                ) > 0 &&
                <span>{dashboard?.pointsTotal - (
                  dashboard?.pointsCompleted.before +
                  dashboard?.pointsCompleted.after
                )}</span>}
              </div>
            </div>
            <div className='card__footer'>
              <div>
                Total: <span>{dashboard?.pointsTotal}</span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
