import { useEffect, useState } from 'react'
import Statistics from './Statistics'
import Resume from './Resume'
import StatisticsSameday from './StatisticsSameday'
import ResumeSameday from './ResumeSameday'
import StatisticsExpress from './StatisticsExpress'
// import ResumeExpress from "./ResumeExpress";
import { useDispatch, useSelector } from 'react-redux'
import {
  dashboardSelector,
  dashboardSamedaySelector,
  dashboardExpressSelector,
  pointsFinalizedSelector,
  ordersCompletedSelector,
  ordersPickSelector
} from '../../selectors/DashboardSelectors'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import * as dashboardActions from '../../actions/DashboardActions'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}
function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFF',
    margin: '62px 0'
  }
}))
const Dashboard = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const dispatch = useDispatch()
  const dashboard = useSelector(dashboardSelector)
  const pointsFinalized = useSelector(pointsFinalizedSelector)
  const ordersPick = useSelector(ordersPickSelector)
  const ordersCompleted = useSelector(ordersCompletedSelector)

  const dashboardSameday = useSelector(dashboardSamedaySelector)
  const dashboardExpress = useSelector(dashboardExpressSelector)
  const pointsFinalizedSameday = {
    after:
      dashboardSameday?.pointsTotal > 0
        ? Math.round(
          (dashboardSameday?.pointsCompletedGroup?.after * 100) / dashboardSameday?.pointsTotal
        )
        : 0,
    before:
      dashboardSameday?.pointsTotal > 0
        ? Math.round(
          (dashboardSameday?.pointsCompletedGroup?.before * 100) / dashboardSameday?.pointsTotal
        )
        : 0
  }
  const ordersCompletedSameday = {
    after:
      dashboardSameday?.ordersCompleted > 0
        ? Math.round(
          (dashboardSameday?.ordersCompletedGroup?.after * 100) / dashboardSameday?.ordersTotal
        )
        : 0,
    before:
      dashboardSameday?.ordersCompleted > 0
        ? Math.round(
          (dashboardSameday?.ordersCompletedGroup?.before * 100) / dashboardSameday?.ordersTotal
        )
        : 0
  }

  useEffect(() => {
    dispatch(dashboardActions.getDashboard({ date: '2020-08-02' }))
    dispatch(dashboardActions.getDashboardSameday({ date: '2020-08-02' }))
    dispatch(dashboardActions.getDashboardExpress({ date: '2020-08-02' }))
  }, [dispatch])

  return (
    <div>
      {dashboard != null ? (
        <>
          <div className={classes.root}>
            <Tabs centered value={value} onChange={handleChange} aria-label='simple tabs example'>
              <Tab label='Nextday' {...a11yProps(0)} />
              <Tab label='Sameday' {...a11yProps(1)} />
              <Tab label='Express' {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div>
                <Statistics dashboard={dashboard} />
                <Resume
                  dashboard={dashboard}
                  pointsFinalized={pointsFinalized}
                  ordersCompleted={ordersCompleted}
                  ordersPick={ordersPick}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <StatisticsSameday dashboard={dashboardSameday} />
                <ResumeSameday
                  dashboard={dashboardSameday}
                  pointsFinalized={pointsFinalizedSameday}
                  ordersCompleted={ordersCompletedSameday}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div>
                <StatisticsExpress dashboard={dashboardExpress} />
              </div>
            </TabPanel>
          </div>
        </>
      ) : (
        <div className='loader'></div>
      )}
    </div>
  )
}
export default Dashboard
