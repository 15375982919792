export const sameDayZoneValidation = (service, zone) =>
  service === 'sameday' && (zone.id === 3 || zone.id === 4)

export const caculateMaxDistance = (points, origin, getDistance) => {
  let maxDistance = 0
  points.forEach((point) => {
    const pointDetail = point
    const newDistance = getDistance(
      { latitude: origin.lat, longitude: origin.lng },
      { latitude: pointDetail.lat, longitude: pointDetail.lng }
    )
    maxDistance = maxDistance + newDistance
    if (newDistance > maxDistance) {
      maxDistance = newDistance
    }
  })
  return maxDistance
}

export const originOrDestinationValidation = (pointsCopy, newPoint, index, edit) => {
  const pointsToSend = [...pointsCopy]
  if (!edit) {
    pointsToSend.push(newPoint)
  } else {
    pointsToSend[index] = newPoint
  }
  return pointsToSend
}
