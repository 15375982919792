import { createSelector } from '@reduxjs/toolkit'

export const ordersStateSelector = createSelector(
  (state) => state.orders,
  (orders) => orders
)

export const ordersSelector = createSelector(
  ordersStateSelector,
  (state) => state.orders
)

export const statusSelector = createSelector(
  ordersStateSelector,
  (state) => state.status
)

export const expressSelector = createSelector(
  ordersStateSelector,
  (state) => state.express
)
