import React from 'react'
import { makeStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import './Drawer.scss'
import moment from 'moment'
import 'moment/locale/es'
const useStyles = makeStyles({
  list: {
    width: 400
  },
  fullList: {
    width: 'auto'
  }
})

export default function TemporaryDrawer (props) {
  const classes = useStyles()
  const { open, toggleDrawer, point } = props
  const money = (cents = 0) => {
    return (
      'S/.' +
      (cents / 100).toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2
      })
    )
    // return 'S/.' + (cents / 100.0).toFixed(2)
  }
  const list = (point) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className='order__content'>
        <div className='order__statusContent'>
          <h3>Resumen General</h3>
          <div className='order__row col-2'>
            <div className='order__col text-secondary'>Dirección</div>
            <div className='order__col'>{point?.address}</div>
            <div className='order__col text-secondary'>Nombre</div>
            <div className='order__col'>{point?.contact?.name}</div>
            <div className='order__col text-secondary'>Telefono</div>
            <div className='order__col'>{point?.contact?.phone}</div>
            <div className='order__col text-secondary'>Instrucciones</div>
            <div className='order__col'>{point?.instructions}</div>
            <div className='order__col text-secondary'>Estado</div>
            <div className='order__col'>{point?.statePoint}</div>
            <div className='order__col text-secondary'>Llegada</div>
            <div className='order__col'>
              {point?.done ? moment(point?.done).format('LLL') : 'Pendiente'}
            </div>
            <div className='order__col text-secondary'>Recibido Por</div>
            <div className='order__col'>{point?.chargeReceivedBy}</div>
            <div className='order__col text-secondary'>Descripción</div>
            <div className='order__col'>{point?.descriptionPoint}</div>
          </div>
        </div>
        <div className='order__card'>
          <h3>Incidente del Punto</h3>
          <div className='order__cardContent'>
            <h3>Hacia el cliente</h3>
            <div className='order__row col-2'>
              <div className='order__col text-secondary'>Valor Inicial</div>
              <div className='order__col'>
                {point.amountCustomerUpdated
                  ? money(
                    point.typeUpdated === 'decrease'
                      ? point.price + point.amountCustomerUpdated
                      : point.price - point.amountCustomerUpdated
                  )
                  : money(point.price)}
              </div>
              <div className='order__col text-secondary'>Cobro Extra</div>
              <div className='order__col'>
                +
                {money(
                  point.typeUpdated === 'increase' &&
                    point.amountCustomerUpdated > 0
                    ? point.amountCustomerUpdated
                    : 0
                )}
              </div>
              <div className='order__col text-secondary'>Descuento</div>
              <div className='order__col'>
                -
                {money(
                  point.typeUpdated === 'decrease' &&
                    point.amountCustomerUpdated > 0
                    ? point.amountCustomerUpdated
                    : 0
                )}
              </div>
              <div className='order__col text-secondary'>Valor Final</div>
              <div className='order__col'>{money(point.price)}</div>
              <div className='order__col text-secondary'>Motivo</div>
              <div className='order__col'>
                {point.amountCustomerUpdated && point.amountCustomerUpdated > 0
                  ? point.motiveUpdated
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <div className='order__card'>
          <div className='order__cardContent'>
            <h3>Hacia el mensajero</h3>
            <div className='order__row col-2'>
              <div className='order__col text-secondary'>Valor Inicial</div>
              <div className='order__col'>
                {point.amountMotorizedsUpdated
                  ? money(
                    point.typeUpdated === 'decrease'
                      ? point.partnerProfit + point.amountMotorizedsUpdated
                      : point.partnerProfit - point.amountMotorizedsUpdated
                  )
                  : money(point.partnerProfit)}
              </div>
              <div className='order__col text-secondary'>Cobro Extra</div>
              <div className='order__col'>
                {money(
                  point.typeUpdated === 'increase' &&
                    point.amountMotorizedsUpdated > 0
                    ? point.amountMotorizedsUpdated
                    : 0
                )}
              </div>
              <div className='order__col text-secondary'>Descuento</div>
              <div className='order__col'>
                {money(
                  point.typeUpdated === 'decrease' &&
                    point.amountMotorizedsUpdated > 0
                    ? point.amountMotorizedsUpdated
                    : 0
                )}
              </div>
              <div className='order__col text-secondary'>Valor Final</div>
              <div className='order__col'>{money(point.partnerProfit)}</div>
              <div className='order__col text-secondary'>Motivo</div>
              <div className='order__col'>
                {point.amountMotorizedsUpdated &&
                point.amountMotorizedsUpdated > 0
                  ? point.motiveUpdated
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <div className='order__card'>
          <h3>Cargo</h3>
          <div className='order__cardContent'>
            <div className='order__row col-2'>
              <img
                src={point?.chargePointImage}
                alt='Sin cargar'
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <React.Fragment>
        <Drawer anchor='right' open={open} onClose={toggleDrawer}>
          {list(point)}
        </Drawer>
      </React.Fragment>
    </div>
  )
}
