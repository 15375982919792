import React from 'react'

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  return tooLow ? 0 : tooHigh ? 100 : +percentage
}

const Circle = ({ colour, pct }) => {
  const r = 40
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <circle
      r={r}
      cx={50}
      cy={50}
      fill='transparent'
      stroke={strokePct !== circ ? colour : ''}
      strokeWidth={'1.3rem'}
      strokeDasharray={circ}
      strokeDashoffset={pct > 0 ? strokePct : 0}
    ></circle>
  )
}

const Text = ({ percentage }) => {
  return (
    <text
      x='48%'
      y='45%'
      dominantBaseline='central'
      textAnchor='middle'
      fontSize={'1.5em'}
    >
      {percentage.toFixed(0)}%
    </text>
  )
}

const Pie = ({ percentage, colour }) => {
  const pct = percentage > 0 ? cleanPercentage(percentage) : 1
  return (
    <svg width={110} height={110}>
      <g transform={`rotate(-90 ${'50 50'})`}>
        <Circle colour='lightgrey' />
        <Circle colour={colour} pct={pct} />
      </g>
      <Text percentage={pct} />
    </svg>
  )
}

export default Pie
