import React, { Fragment, useState } from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import './Detail.scss'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import TripOriginIcon from '@mui/icons-material/TripOrigin'
import DeletePoint from '../Modals/DeletePoint'
import FormIncidence from '../Modals/FormIncidence'
import DetailPoint from './Drawer'
import EditPoint from '../EditPoint/Edit'
import './Points.scss'
import EditPointState from './EditPointState'
import moment from 'moment'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import 'moment/locale/es'

const useStyles = makeStyles((theme) => ({
  buttonPoint: {
    float: 'right',
    width: '120px',
    borderRadius: '5px',
    padding: '6px 15px',
    fontWeight: 0,
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    fontSize: '0.9em',
    textAlign: 'center'
  },
  buttonSelect: {
    float: 'right',
    width: '150px',
    minWidth: '140px',
    borderRadius: '5px',
    padding: '6px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    fontSize: '0.9em',
    textAlign: 'center'
  },
  buttonColor: {
    backgroundColor: '#D9D9D9'
  },
  buttonColorII: {
    backgroundColor: '#54A268',
    color: 'white'
  }
}))

const waitTimeGreaterThanTen = (points) => {
  const totalTime = points?.reduce((a, b) => a?.waitTime + b?.waitTime)
  return totalTime
}
const originStates = [
  { name: 'No Recogido', value: 'No Recogido' },
  { name: 'Recogido', value: 'Recogido' }
]
const destinyStates = [
  { name: 'No Entregado', value: 'No entregado' },
  { name: 'Entregado', value: 'Entregado' }
]
const Points = ({ points, order, reloadOrder }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [showDelete, setDelete] = useState(false)
  const [showIncidence, setIncidence] = useState(false)
  const [detailPoint, setDetail] = useState({})
  const [pivot, setPivot] = useState('')
  // const [newState, setNewState] = useState()
  const toggleDrawer = (point) => () => {
    setDetail(point)
    setOpen(!open)
  }

  const toggleDrawerClose = () => {
    setOpen(!open)
  }
  const [openEdit, setOpenEdit] = useState(false)

  const handleClickOpen = (point, index) => {
    setDetail(point)
    setPivot(index)
    setOpenEdit(true)
  }
  const handleClose = () => {
    setDetail({})
    setOpenEdit(false)
  }

  const handleClickDelete = (point) => () => {
    setDetail(point)
    setDelete(true)
  }
  const handleCloseDelete = () => {
    setDelete(false)
  }

  const handleClickIncidence = (point) => () => {
    setDetail(point)
    setIncidence(true)
  }

  const handleCloseIncidence = () => {
    setIncidence(false)
  }
  // const handleNewState = (e) => {
  //   setNewState(e.target.value);
  // }
  // const updatePoint = async (point) => {
  //   try {
  //     const payload = {
  //       data:{
  //         chargeReceivedBy: point.chargeReceivedBy,
  //         StatePoint: point.statePoint,
  //       },
  //       pointId: point._id,
  //       id: order.id,
  //     };
  //     //await OrderController.updatePointState(payload);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      {points?.map((item, index) => (
        <Fragment key={index}>
          <Card className='points'>
            <div className='points__icons-material'>
              {index === 0 && item.statePoint === 'Recogido' && (
                <span>
                  <TripOriginIcon sx={{ fontSize: '2em' }} color='success' />
                </span>
              )}
              {index === 0 && item.statePoint !== 'Recogido' && (
                <span>
                  <TripOriginIcon sx={{ fontSize: '2em' }} color='error' />
                </span>
              )}
              {index > 0 && item.statePoint === 'Entregado' && (
                <span>
                  <LocationOnIcon sx={{ fontSize: '2em' }} color='success' />
                </span>
              )}
              {index > 0 && item.statePoint !== 'Entregado' && (
                <span>
                  <LocationOnIcon sx={{ fontSize: '2em' }} color='error' />
                </span>
              )}
            </div>
            <div className='points__content'>
              <div style={{ marginBottom: '15px' }}>
                <h3 style={{ margin: '0' }}>
                  {index}. {item.contact.name}
                </h3>
              </div>
              <div className='content'>
                <div>
                  <span className='content__title'>Dirección:</span>
                  <span className='content__description'> {item.address} </span>

                  <span className='content__title'>Indicaciones:</span>
                  <span className='content__description'>{item.instructions}</span>

                  <span className='content__title'>Contacto: </span>
                  <span className='content__description'>{item.contact.phone}</span>
                </div>
              </div>
              {order.status !== 'canceled' && (
                <div className='points__buttons'>
                  <Button
                    variant='contained'
                    className='points__button'
                    onClick={toggleDrawer(item)}
                    size='small'
                  >
                    Detalle
                  </Button>

                  <Button
                    variant='contained'
                    className='points__button'
                    size='small'
                    onClick={() => handleClickOpen(item, index)}
                  >
                    Editar
                  </Button>
                  <Button
                    size='small'
                    variant='contained'
                    className='points__button'
                    onClick={handleClickIncidence(item)}
                  >
                    Incidencias
                  </Button>
                  <Button
                    size='small'
                    variant='contained'
                    color='error'
                    onClick={handleClickDelete(item)}
                  >
                    Eliminar
                  </Button>
                </div>
              )}
            </div>
            <div className='points__status'>
              {!item.statePoint ||
              item.statePoint === 'Entregado' ||
              item.statePoint === 'Recogido' ? (
                <div>
                  <div
                    className={
                      item.statePoint === 'Entregado' || item.statePoint === 'Recogido'
                        ? `${classes.buttonPoint} ${classes.buttonColorII}`
                        : `${classes.buttonPoint} ${classes.buttonColor}`
                    }
                  >
                    {item.statePoint ? item.statePoint : 'Pendiente'}
                  </div>
                </div>
                  ) : (
                    <div>
                      <EditPointState
                        reloadOrder={reloadOrder}
                        item={item}
                        order={order}
                        states={index === 0 ? originStates : destinyStates}
                        customStyle={classes.buttonSelect}
                  />
                    </div>
                  )}
              {item?.waitTime >= 0 && item?.done && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <span
                    style={{
                      backgroundColor: waitTimeGreaterThanTen(points) >= 10 ? '#f50057' : '#ECC94B',
                      padding: '3px',
                      color: waitTimeGreaterThanTen(points) >= 10 ? 'white' : '#3F4D55',
                      fontWeight: '500',
                      fontSize: '0.8em',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <AvTimerIcon style={{ marginRight: '10px' }} />
                    <span
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        marginRight: '5px'
                      }}
                    >
                      {item?.done && item?.waitTime}
                    </span>{' '}
                    min
                  </span>
                </div>
              )}

              <div>
                {(item?.statePoint === 'Entregado' || item?.statePoint === 'Recogido') && (
                  <p>
                    Pedido completado el <span>{moment(item?.done).format('L')}</span> a las{' '}
                    {moment(item?.done).format('LT')}
                  </p>
                )}
              </div>
            </div>
          </Card>
          <div className='points__connect'>
            <ArrowDownwardIcon
              fontSize='large'
              style={{ color: item.statePoint === 1 ? '#54A268' : '#F0F0F0' }}
            />
          </div>
        </Fragment>
      ))}
      <DetailPoint open={open} toggleDrawer={toggleDrawerClose} point={detailPoint} order={order} />
      {openEdit && (
        <EditPoint
          openEdit={openEdit}
          edit={true}
          handleClose={handleClose}
          order={order}
          index={pivot}
          point={detailPoint}
          orderStatus={order?.status}
          service={order?.service}
        />
      )}
      <FormIncidence
        showIncidence={showIncidence}
        handleCloseIncidence={handleCloseIncidence}
        order={order}
        point={detailPoint}
      />
      <DeletePoint
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
        point={detailPoint}
      />
    </>
  )
}
export default Points
