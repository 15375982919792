import React from 'react'
import EditClient from '../components/Clients/EditClient'

const EditClientPage = () => {
  return (
    <EditClient />
  )
}

export { EditClientPage }
