import {
  geocodeByPlaceId,
  geocodeByAddress
} from 'react-google-places-autocomplete'

class LocationService {
  static geocodeByPlaceId = async (placeId) => geocodeByPlaceId(placeId)

  static geocodeByAddress = async (place) => geocodeByAddress(place)

  static geocodeByLatLng = ({ lat, lng } = {}) => {
    const geocoder = new window.google.maps.Geocoder()
    const { OK } = window.google.maps.GeocoderStatus
    const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) }
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status !== OK) {
          return reject(status)
        }
        return resolve(results)
      })
    })
  }
}

export default LocationService
