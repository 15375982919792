import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import './Detail.scss'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import IconButton from '@mui/material/IconButton'
import ModalPartner from './ModalPartner'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { formatPayment, orderDetail } from '../../assets/js/formaters'
import moment from 'moment'
import 'moment/locale/es'
const useStyles = makeStyles((theme) => ({
  button: {
    width: '70%',
    height: '40px',
    backgroundColor: '#ECC94B',
    fontWeight: 'bold',
    color: '#3F4D55'
  },
  copyButton: {
    color: '#2e0049',
    fontSize: '1.1em'
  }
}))
// confirma si el status es completed o cancelled
const statusDone = (data) => data?.status !== 'completed' && data?.status !== 'canceled'

export default function CardDetail ({ order, reloadOrder }) {
  const classes = useStyles()
  const MAGIC_NUMBER = 100
  const MAGIC_KM = 1000
  return (
    <Card className='card__detail'>
      <div className='partner'>
        <span>
          <h3>Detalle del pedido</h3>
          <div
            className='state__point'
            style={{
              backgroundColor: orderDetail.statusFormat[order?.status],
              width: 100,
              textAlign: 'center',
              borderRadius: '4px',
              color: orderDetail.colorStatus[order?.status],
              padding: '4px'
            }}
          >
            {orderDetail.formatStatus[order?.status]}
          </div>
        </span>
        <hr className='line' />
      </div>
      <div className='detail__content'>
        <div>
          <span>Cliente: </span> <span>{order?.customer.user.name.first}</span>
        </div>
        <div>
          <span>Marca: </span> <span>{order?.customer.user.name.last}</span>
        </div>
        <div>
          <span>Tipo de pago </span> <span>{formatPayment[order?.paymentUsed]}</span>
        </div>
        <div>
          <span>ID de Pedido: </span>
          <span id='order__id'>
            {order?._id}
            <IconButton
              onClick={() =>
                navigator.clipboard
                  .writeText(document.querySelector('span#order__id').textContent)
                  .then(NotificationManager.success('ID copiado exitosamente'))
              }
              title='Copiar'
              size='small'
              style={{ marginLeft: '10px' }}
            >
              <FileCopyIcon className={classes.copyButton} />
            </IconButton>
          </span>
        </div>
        <div>
          <span>Servicio: </span> <span>{orderDetail.formatService[order?.service]}</span>
        </div>
        {order?.reserved && (
          <div>
            <span>Reservado para: </span>
            <span>
              {moment(order?.reserved).format('L')} {moment(order?.reserved).format('LT')}
            </span>
          </div>
        )}

        <div>
          <span>Teléfono: </span>{' '}
          <span id='phone'>
            {order?.customer.phone}
            <IconButton
              onClick={() =>
                navigator.clipboard
                  .writeText(document.querySelector('span#phone').textContent)
                  .then(NotificationManager.success('Teléfono copiado exitosamente'))
              }
              title='Copiar'
              size='small'
              style={{ marginLeft: '10px' }}
            >
              <FileCopyIcon className={classes.copyButton} />
            </IconButton>
          </span>
        </div>
        <div>
          <span>Precio: </span> <span>{`S/. ${(order?.amount / MAGIC_NUMBER).toFixed(2)}`}</span>
        </div>
        <div>
          <span>Pago al mensajero: </span>{' '}
          <span>{`S/. ${(order?.partnerProfit / MAGIC_NUMBER).toFixed(2)}`}</span>
        </div>
        {order?.distance && (
          <div>
            <span>Distancia: </span> <span>{(order?.distance / MAGIC_KM).toFixed(2)} km</span>
          </div>
        )}
      </div>

      {order?.partner && (
        <>
          <div className='partner'>
            <h3>Mensajero</h3>
            <hr className='line' />
          </div>
          <div className='assign__partner'>
            <div style={{ width: '100%' }}>
              <div>
                <div className='div__inside'>
                  <span>Nombre del Mensajero: </span>
                  <span>
                    {order?.partner?.user?.name?.first} {order?.partner?.user?.name.last}
                  </span>
                </div>
                <div className='div__inside'>
                  <span>Vehículo: </span>
                  <span>{order?.partner.vehicle}</span>
                </div>
                <div className='div__inside'>
                  <span>Teléfono: </span>
                  <span>{order?.partner.phone}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div style={{ marginBottom: 20 }}>
        {statusDone(order) && (
          <ModalPartner
            orderId={order?._id ?? order?.id}
            reloadOrder={reloadOrder}
            hasDetail
            rider={
              order?.partner &&
              `${order?.partner?.user?.name?.first} ${order?.partner?.user?.name?.last}`
            }
          />
        )}
      </div>
    </Card>
  )
}
