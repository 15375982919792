import client from './HttpClient'
import order from './HttpOrder'

class OrdersController {
  static async getOrders () {
    try {
      const token = localStorage.getItem('token')
      const response = await client.get('/orders', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data.orders
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getOrdersFilters (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.post(
        `/admin/api/list-orders?limit=100&&page=${
          payload.page > 0 && payload.page ? payload.page : 1
        }`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getExport (params) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.post(
        `/admin/orders/export?from=${params.from}&to=${params.to}`,
        {
          status: params.status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  static async getExpress (payload) {
    try {
      const token = localStorage.getItem('token')
      const response = await order.post(
        `/admin/api/list-orders-express?limit=20&&page=${
          payload.page > 0 && payload.page ? payload.page : 1
        }`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default OrdersController
