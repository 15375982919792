import { createReducer } from '@reduxjs/toolkit'

import * as clientsActions from '.././actions/ClientsActions'

const initialState = {
  clients: null,
  client: null
}

const ClientsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clientsActions.getClients, (state, { payload }) => ({
      ...state,
      clients: payload
    }))
    .addCase(clientsActions.setClients, (state, { payload }) => ({
      ...state,
      clients: payload
    }))
    .addCase(clientsActions.setClient, (state, { payload }) => ({
      ...state,
      client: payload
    }))
})

export default ClientsReducer
