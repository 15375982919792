import React from 'react'
import { Provider } from 'react-redux'
import { store } from '.././store'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Private from './middlewares/Private'
import {
  ClientsPage,
  CreatePartnerPage,
  DashboardPage,
  EditClientPage,
  EditPartnerPage,
  LoginPage,
  MapPage,
  OrdersExpressPage,
  OrdersPage,
  PartnersPage,
  SettingsPage
} from '../pages'
import OrderDetail from '../components/Detail/OrderDetail'
import Header from '../components/Header'

const AppRouter = () => {
  const token = localStorage.getItem('token')
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              token ? <Navigate to='/dashboard/inicio' replace /> : <Navigate to='/login' replace />
            }
          ></Route>
          <Route path='/login' element={<LoginPage />}></Route>
          <Route
            path='/dashboard'
            element={
              <Private>
                <Header />
              </Private>
            }
          >
            <Route path='inicio' element={<DashboardPage />}></Route>
            <Route path='pedidos' element={<OrdersPage />}></Route>
            <Route path='express' element={<OrdersExpressPage />}></Route>
            <Route path='pedidos/:idPedido' element={<OrderDetail />}></Route>
            <Route path='clientes' element={<ClientsPage />}></Route>
            <Route path='mensajeros' element={<PartnersPage />}></Route>
            <Route path='tiempo-real' element={<MapPage />}></Route>
            <Route path='crear-mensajero' element={<CreatePartnerPage />}></Route>
            <Route path='clientes/editar/:idCliente' element={<EditClientPage />}></Route>
            <Route path='mensajeros/editar/:idMensajero' element={<EditPartnerPage />}></Route>
            <Route path='configuracion' element={<SettingsPage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default AppRouter
