import { Card } from '@mui/material'
import './MiniDetail.scss'

const RiderInfo = ({ order }) => {
  return (
    <Card style={{ padding: '10px', height: '25vh', overflow: 'auto' }}>
      <div className='md__header'>
        <h4>Mensajero</h4>
      </div>
      <div>
        <hr />
      </div>
      <div>
        {order?.status !== 'pending'
          ? (
            <div style={{ width: '100%' }}>
              <div>
                <div className='md__info'>
                  <span>Nombre: </span>
                  <span>
                    {order?.partner?.user?.name?.first}{' '}
                    {order?.partner?.user?.name?.last}
                  </span>
                </div>
                <div className='md__info'>
                  <span>Vehículo: </span>
                  <span>{order?.partner?.vehicle}</span>
                </div>
                <div className='md__info'>
                  <span>Teléfono: </span>
                  <span>{order?.partner?.phone}</span>
                </div>
              </div>
              <div className='md__info'>
                <span>Pago al mensajero: </span>{' '}
                <span>{`S/. ${(order?.partnerProfit / 100).toFixed(
                2
              )}`}</span>
              </div>
            </div>
            )
          : (
            <div>
              <div className='md__info'>No hay mensajero asignado</div>
            </div>
            )}
      </div>
    </Card>
  )
}

export default RiderInfo
