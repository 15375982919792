import { combineReducers } from 'redux'
import statistics from './StatisticsReducer'
import dashboard from './DashboardReducer'
import orders from './OrdersReducer'
import order from './OrderReducer'
import login from './LoginReducer'
import partners from './PartnersReducer'
import clients from './ClientsReducer'
import settings from './SettingsReducer'

export default combineReducers({
  statistics,
  dashboard,
  orders,
  order,
  login,
  partners,
  clients,
  settings
})
