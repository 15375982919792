import React from 'react'
import AppRouter from './routes/AppRouter'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2e0049',
      contrastText: 'white'
    },
    secondary: {
      main: '#b4ff37',
      contrastText: '#2e0049'
    }
  }
})

const App = () => {
  return <ThemeProvider theme={theme}>
    <AppRouter/>
  </ThemeProvider>
}

export default App
