import { createReducer } from '@reduxjs/toolkit'

import * as loginActions from '.././actions/LoginActions'

const initialState = {
  token: null,
  user: {}
}

const LoginReducer = createReducer(initialState, (builder) => {
  builder.addCase(loginActions.setToken, (state, { payload }) => ({
    ...state,
    token: payload
  }))
})

export default LoginReducer
