import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import OrderController from '../../controllers/OrderController'
import SnackBar from '../Modals/SnackBar'

const EditPointState = ({ item, order, states, customStyle, reloadOrder }) => {
  const [newState, setNewState] = useState(item?.statePoint)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleNewState = async (e) => {
    setNewState(e.target.value)

    try {
      const payload = {
        data: {
          chargeReceivedBy: item?.chargeReceivedBy,
          StatePoint: e.target.value
        },
        pointId: item?._id,
        id: order._id ?? order.id
      }
      await OrderController.updatePointState(payload)
      setMessage('Estado editado correctamente')
      setType('success')
      setOpen(true)
      setTimeout(() => reloadOrder(), 2000)
    } catch (error) {
      setMessage('Error al editar estado')
      setType('error')
      setOpen(true)
      console.log(error)
    }
  }

  return (
    <>
      <Select className={customStyle} value={newState} onChange={handleNewState}>
        {states.map((state, index) => (
          <MenuItem key={index} value={state.value}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
      <SnackBar message={message} type={type} openValue={open} handleClose={handleClose} />
    </>
  )
}

export default EditPointState
