import React from 'react'
import Clients from '../components/Clients/Clients'

const ClientsPage = () => {
  return (
    <Clients />
  )
}

export { ClientsPage }
