import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import './clients.scss'
import { useDispatch, useSelector } from 'react-redux'
import { clientsSelector } from '../../selectors/ClientsSelectors'
import * as clientsActions from '../../actions/ClientsActions'
import MenuItem from '@mui/material/MenuItem'
import 'moment/locale/es'
import Loader from '../Modals/Loader'
import Paginator from '../Modals/Paginator'
import { renderTable } from '../../assets/js/helpers'
import { CLIENT_HEADERS, CommonTable } from '../Common'

const list = [
  { title: 'Clientes', value: false },
  { title: 'Empresas', value: true }
]

const Clients = () => {
  const dispatch = useDispatch()
  const data = useSelector(clientsSelector)
  const [search, setSearch] = useState()
  const [status, setStatus] = useState(true)
  const [page, setPage] = useState(1)
  const handleSearch = (event) => {
    setSearch(event.target.value)
    const search = `search=${event.target.value}`
    const company = status
    dispatch(clientsActions.getClients({ payload: { page: 1, company, data: search } }))
  }

  const handleFilterStatus = (event) => {
    setStatus(event.target.value)
    const company = event.target.value
    dispatch(
      clientsActions.getClients({
        payload: { page: 1, company, data: '' }
      })
    )
    setPage(1)
  }

  const handleChangePage = (event, value) => {
    setPage(value)
    const payloadFinal = {
      page: value,
      data: search ? `search=${search}` : '',
      company: status
    }
    if (value <= data?.totalPages) {
      dispatch(clientsActions.getClients({ payload: payloadFinal }))
    }
  }
  useEffect(() => {
    const search = ''
    dispatch(
      clientsActions.getClients({
        payload: {
          page: 1,
          company: status,
          data: search
        }
      })
    )
  }, [dispatch, status])
  return (
    <div
      style={{
        marginLeft: '5%',
        marginRight: '5%',
        width: '90%',
        marginTop: '60px'
      }}
    >
      <div className='header__titles'>
        <h2>Lista de Clientes</h2>
      </div>
      <div className='header'>
        <TextField
          select
          label='Tipo de Cliente'
          value={status}
          onChange={handleFilterStatus}
          size='small'
          variant='outlined'
          style={{ width: 180 }}
        >
          {list.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
        <div>
          <TextField
            id='outlined-basic'
            label='Buscar'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearch}
            style={{ width: 220, marginLeft: 20 }}
          />
        </div>
      </div>
      <div className='content__table'>
        {!data ? (
          <Loader />
        ) : (
          renderTable(
            data.docs.length,
            <CommonTable
              headers={CLIENT_HEADERS}
              docs={data?.docs}
              action={(id) => window.open(`clientes/editar/${id}`, '_blank')}
              type='client'
            />
          )
        )}

        <Paginator data={data} page={page} handleChange={handleChangePage} />
      </div>
    </div>
  )
}
export default Clients
