import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as ordersActions from './actions/OrdersActions'
import * as orderActions from './actions/OrderActions'

import useSocket from 'use-socket.io-client'
const useWebsocket = (orderId) => {
  const [socket] = useSocket(process.env.REACT_APP_NIREX_API_URL)
  socket.connect()
  const dispatch = useDispatch()
  const withStatusYesterday = {
    page: 1,
    data: {
      action: 'pending'
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('token')
    socket.on('connect', () => {
      socket.emit('authenticate', { token })
      socket.on('authenticated', () => {
        socket.on('welcome', (data) => {
          console.log(data)
        })
      })
      socket.on('order_taken', () => {
        // dispatch(
        //   ordersActions.getOrdersFilters({ payload: withStatusYesterday })
        // );
        dispatch(ordersActions.getOrdersExpress({ payload: withStatusYesterday }))
        if (orderId !== null) {
          const payload = { id: orderId }
          dispatch(orderActions.getOrder({ payload }))
        }
      })
      socket.on('order canceled', () => {
        // dispatch(
        //   ordersActions.getOrdersFilters({ payload: withStatusYesterday })
        // );
        dispatch(ordersActions.getOrdersExpress({ payload: withStatusYesterday }))
        if (orderId !== null) {
          const payload = { id: orderId }
          dispatch(orderActions.getOrder({ payload }))
        }
      })
      socket.on('order point', () => {
        // dispatch(
        //   ordersActions.getOrdersFilters({ payload: withStatusYesterday })
        // );
        dispatch(ordersActions.getOrdersExpress({ payload: withStatusYesterday }))
        if (orderId !== null) {
          const payload = { id: orderId }
          dispatch(orderActions.getOrder({ payload }))
        }
      })
      socket.on('order tracking', () => {
        // dispatch(
        //   ordersActions.getOrdersFilters({ payload: withStatusYesterday })
        // );
        dispatch(ordersActions.getOrdersExpress({ payload: withStatusYesterday }))
        if (orderId !== null) {
          const payload = { id: orderId }
          dispatch(orderActions.getOrder({ payload }))
        }
      })
    })
  }, [])
}

export default useWebsocket
