import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import LoginController from '../controllers/LoginController'

export const types = {
  LOGIN: 'LOGIN',
  SET_TOKEN: 'SET_TOKEN'
}

export const login = createAsyncThunk(
  types.LOGIN,
  async ({ payload }, { dispatch }) => {
    try {
      const response = await LoginController.login({ payload })
      localStorage.setItem('token', response.token)
      dispatch(setToken(response.token))
      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
)

export const setToken = createAction(types.SET_TOKEN, (token) => ({
  payload: token
}))
